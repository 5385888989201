import React, { useEffect, useState } from "react";
import "../../asset/css/content_Home/WhySunny.css";
import img1 from "../../asset/images/Icon/m1.png";
import img2 from "../../asset/images/Icon/m2.png";
import img3 from "../../asset/images/Icon/m3.png";
import img4 from "../../asset/images/Icon/m4.png";
import { useMediaQuery } from "react-responsive";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "../../config/axiosConfig";

export default function WhySunny(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Họ và tên không được để trống"),
    phoneNumber: Yup.string()
      .matches(
        /^(?:\+?84|0)(?:[35789]\d{8}|1[2689]\d{8})$/,
        "Số điện thoại không hợp lệ"
      )
      .required("Số điện thoại không được để trống"),
    email: Yup.string(),
  });

  const validationSchemaEn = Yup.object().shape({
    name: Yup.string().required("First and last name cannot be left blank"),
    phoneNumber: Yup.string()
      .matches(
        /^(?:\+?84|0)(?:[35789]\d{8}|1[2689]\d{8})$/,
        "Invalid phone number"
      )
      .required("Phone number can not be left blank"),
    email: Yup.string(),
  });

  const onSubmit = (values) => {
    const bodyCreate = JSON.stringify(values);
    axios
      .post("client/v1/cost", bodyCreate)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "Cảm ơn bạn đã quan tâm!",
            text: "Chúng tôi sẽ liên hệ sớm nhất với bạn.",
            customClass: {
              popup: 'my-custom-alert-class',
            },
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
          customClass: {
            popup: 'my-custom-alert-class',
          },
        });
        console.log(error);
      });
  };

  return (
    <div className="bg-page whySunny" id="whySunny">
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="container">
            <div className="sunny-box-ss">
              <p className="sunny-title-ss">Vì sao chọn Sunny Express</p>
              <h4 className="whySunny-ss">VÌ SAO CHỌN CHÚNG TÔI</h4>
            </div>
            <div className="row">
              <div className="col-sm-8 sunny-box">
                <p className="desc">
                  Chúng tôi không chỉ là đối tác, nhà cung ứng dịch vụ mà còn là
                  người đồng hành đáng tin cậy, đem lại sự an tâm và tin tưởng
                  lâu dài cho khách hàng để cùng nhau xây dựng mối quan hệ bền
                  vững, cùng nhau phát triển trong nền kinh tế hội nhập.
                </p>
                <div className="sunny-thum">
                  <div className="row">
                    <div className="col-6">
                      <div className="media">
                        <img className="mr-3" src={img1} width="50px" alt="" />
                        <div className="media-body">Giao hàng nhanh chóng</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="media">
                        <img className="mr-3" src={img2} alt="" />
                        <div className="media-body">Giá cả hợp lý</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="media">
                        <img className="mr-3" src={img3} alt="" />
                        <div className="media-body">Giảm thiểu rủi ro</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="media">
                        <img className="mr-4" src={img4} alt="" />
                        <div className="media-body">Chủ động kiểm soát</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="sheet-box-whySunny">
                  <div className="title-custom" id="baogia">
                    ĐĂNG KÝ NHẬN BÁO GIÁ
                    <p>&nbsp;</p>
                  </div>
                  <Formik
                    initialValues={{ name: "", email: "", phoneNumber: "" }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-group">
                          <Field
                            name="name"
                            type="text"
                            placeholder="Tên"
                            className="form-control inputWhySunny-navbar"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger whySunny-errorDiv"
                          />
                        </div>
                        <div className="form-group inputWhySunny">
                          <Field
                            name="phoneNumber"
                            type="tel"
                            placeholder="SĐT/Zalo"
                            className="form-control inputWhySunny-navbar"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-danger whySunny-errorDiv"
                          />
                        </div>
                        <div className="form-group inputWhySunny">
                          <Field
                            name="email"
                            type="email"
                            placeholder="Email (có thể không cần)"
                            className="form-control inputWhySunny-navbar"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger whySunny-errorDiv"
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          NHẬN BÁO GIÁ
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="WhySunnyWrapContentMb">
            <p className="sunny-titleMb">Vì sao chọn Sunny Express</p>
            <div className="WhySunnyTitleMb">VÌ SAO CHỌN CHÚNG TÔI</div>
            <div className="WhySunnyContenMb">
              Chúng tôi không chỉ là đối tác, nhà cung ứng dịch vụ mà còn là
              người đồng hành đáng tin cậy, đem lại sự an tâm và tin tưởng lâu
              dài cho khách hàng để cùng nhau xây dựng mối quan hệ bền vững,
              cùng nhau phát triển trong nền kinh tế hội nhập.
            </div>

            <div className="sheet-box-whySunnyMb">
              <div className="title-customMb" id="baogia">
                ĐĂNG KÝ NHẬN BÁO GIÁ
                <p>&nbsp;</p>
              </div>
              <Formik
                initialValues={{ name: "", email: "", phoneNumber: "" }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-group">
                      <Field
                        name="name"
                        type="text"
                        placeholder="Tên"
                        className="form-control inputWhySunny-navbarMb"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger-Mb"
                      />
                    </div>
                    <div className="form-group inputWhySunny">
                      <Field
                        name="phoneNumber"
                        type="tel"
                        placeholder="SĐT/Zalo"
                        className="form-control inputWhySunny-navbarMb"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-danger-Mb"
                      />
                    </div>
                    <div className="form-group inputWhySunny">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email (có thể không cần)"
                        className="form-control inputWhySunny-navbarMb"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger-Mb"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn-WhySunny-Mb btn btn-primary"
                    >
                      NHẬN BÁO GIÁ
                    </button>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="sunny-thumMb">
              <div className="row">
                <div className="col-6">
                  <div className="media">
                    <img className="mr-3" src={img1} alt="" />
                    <div className="media-bodyMb">Giao hàng nhanh chóng</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="media">
                    <img className="mr-3" src={img2} alt="" />
                    <div className="media-bodyMb">Giá cả hợp lý</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="media">
                    <img className="mr-3" src={img3} alt="" />
                    <div className="media-bodyMb">Giảm thiểu rủi ro</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="media">
                    <img className="mr-4" src={img4} alt="" />
                    <div className="media-bodyMb">Chủ động kiểm soát</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="container">
          <div className="sunny-box-ss">
            <p className="sunny-title-ss">Why choose Sunny Express</p>
            <h4 className="whySunny-ss">WHY CHOOSE US</h4>
          </div>
          <div className="row">
            <div className="col-sm-8 sunny-box">
              <p className="desc">
                We are not only a partner, a service provider but also a
                reliable companion, bringing peace of mind and long-term trust
                to customers to build a sustainable relationship together,
                develop together. development in an integrated economy.
              </p>
              <div className="sunny-thum">
                <div className="row">
                  <div className="col-6">
                    <div className="media">
                      <img className="mr-3" src={img1} width="50px" alt="" />
                      <div className="media-body">Fast shipping</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="media">
                      <img className="mr-3" src={img2} alt="" />
                      <div className="media-body">Reasonable price</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="media">
                      <img className="mr-3" src={img3} alt="" />
                      <div className="media-body">Risk reduction</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="media">
                      <img className="mr-4" src={img4} alt="" />
                      <div className="media-body">Actively control</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="sheet-box-whySunny">
                <div className="title-custom" id="baogia">
                  SIGN UP TO RECEIVE PRICE
                  <p>&nbsp;</p>
                </div>
                <Formik
                  initialValues={{ name: "", email: "", phoneNumber: "" }}
                  validationSchema={validationSchemaEn}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <Field
                          name="name"
                          type="text"
                          placeholder="Name"
                          className="form-control inputWhySunny-navbar"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger whySunny-errorDiv"
                        />
                      </div>
                      <div className="form-group inputWhySunny">
                        <Field
                          name="phoneNumber"
                          type="tel"
                          placeholder="Phone/Zalo"
                          className="form-control inputWhySunny-navbar"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-danger whySunny-errorDiv"
                        />
                      </div>
                      <div className="form-group inputWhySunny">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email (may not need)"
                          className="form-control inputWhySunny-navbar"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger whySunny-errorDiv"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        GET A PRICE
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="WhySunnyWrapContentMb">
          <p className="sunny-titleMb">Vì sao chọn Sunny Express</p>
          <div className="WhySunnyTitleMb">VÌ SAO CHỌN CHÚNG TÔI</div>
          <div className="WhySunnyContenMb">
            Chúng tôi không chỉ là đối tác, nhà cung ứng dịch vụ mà còn là người
            đồng hành đáng tin cậy, đem lại sự an tâm và tin tưởng lâu dài cho
            khách hàng để cùng nhau xây dựng mối quan hệ bền vững, cùng nhau
            phát triển trong nền kinh tế hội nhập.
          </div>

          <div className="sheet-box-whySunnyMb">
            <div className="title-customMb" id="baogia">
              ĐĂNG KÝ NHẬN BÁO GIÁ
              <p>&nbsp;</p>
            </div>
            <Formik
              initialValues={{ name: "", email: "", phoneNumber: "" }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      name="name"
                      type="text"
                      placeholder="Tên"
                      className="form-control inputWhySunny-navbarMb"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger-Mb"
                    />
                  </div>
                  <div className="form-group inputWhySunny">
                    <Field
                      name="phoneNumber"
                      type="tel"
                      placeholder="SĐT/Zalo"
                      className="form-control inputWhySunny-navbarMb"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-danger-Mb"
                    />
                  </div>
                  <div className="form-group inputWhySunny">
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email (có thể không cần)"
                      className="form-control inputWhySunny-navbarMb"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger-Mb"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-WhySunny-Mb btn btn-primary"
                  >
                    NHẬN BÁO GIÁ
                  </button>
                </Form>
              )}
            </Formik>
          </div>

          <div className="sunny-thumMb">
            <div className="row">
              <div className="col-6">
                <div className="media">
                  <img className="mr-3" src={img1} alt="" />
                  <div className="media-bodyMb">Giao hàng nhanh chóng</div>
                </div>
              </div>
              <div className="col-6">
                <div className="media">
                  <img className="mr-3" src={img2} alt="" />
                  <div className="media-bodyMb">Giá cả hợp lý</div>
                </div>
              </div>
              <div className="col-6">
                <div className="media">
                  <img className="mr-3" src={img3} alt="" />
                  <div className="media-bodyMb">Giảm thiểu rủi ro</div>
                </div>
              </div>
              <div className="col-6">
                <div className="media">
                  <img className="mr-4" src={img4} alt="" />
                  <div className="media-bodyMb">Chủ động kiểm soát</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
