import axios from "axios";

// baseURL: "http://192.168.110.145:8086",
const url = process.env.REACT_APP_SUNNY_CLIENT_URL
// const url = window.REACT_APP_SUNNY_CLIENT_URL;
const axiosConfig = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization, X-Requested-With, Accept",
  },
});
// Hàm này truyền token vào header của request
// export function setAuthToken(token) {
//   if (token) {
//     axiosConfig.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } else {
//     delete axiosConfig.defaults.headers.common["Authorization"];
//   }
// }

export default axiosConfig;
