import React from "react";

export default function ConvertHTMLToReact(html, length) {
  const truncatedHTML = truncateString(html, length);

  return <div dangerouslySetInnerHTML={{ __html: truncatedHTML }} />;
}

function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  } else {
    if(typeof maxLength==='undefined'){
      return str
    }else{
      return str.substring(0, maxLength) + "..." 
    }
  }
}
