import React, { useEffect } from "react";
import "../asset/css/Page/ServiceSites.css";
import Footer from "../component/shared/Footer";
import MyService from "../component/shared/Advertisement/MyService";
import IntroductionList from "../component/shared/Advertisement/IntroductionList";
import Contact from "../component/shared/Contact/Contact";
import ListService from "../component/content_Service/ListService";
import HotNews from "../component/shared/Advertisement/HotNews";
import BannerAir from "../asset/images/Banner/banner-dich-vu.jpg";
import img1 from "../asset/images/Icon/mb.png";
import img2 from "../asset/images/Icon/xe.png";
import img3 from "../asset/images/Icon/tau.png";
import img4 from "../asset/images/Icon/nha.png";
import img5 from "../asset/images/Icon/khac.png";
import { useMediaQuery } from "react-responsive";
import { animateScroll as scroll } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useState } from "react";

export default function NewsSites(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const [dataContentService, setDataContentService] = useState([]);
  const [dataListService, setDataListService] = useState();
  const [postHighLight, setPostHighLight] = useState();
  const [menuLeftAbout, setMenuLeftAbout] = useState();
  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  let idServiceList = localStorage.getItem("serCode");
  let idCategory = localStorage.getItem("cateCode");

  useEffect(() => {
    scroll.scrollToTop();
    if (
      localStorage.getItem("cateCode") ||
      localStorage.getItem("serCode") === null
    ) {
      axios
        .post(`${url}/client/v1/home`)
        .then((response) => {
          for (let i = 0; i < response.data.categoryClientInfo.length; i++) {
            if (response.data.categoryClientInfo[i].categoryEn === "ABOUT US") {
              localStorage.setItem(
                "cateCode",
                response.data.categoryClientInfo[i].id
              );
            }
            if (response.data.categoryClientInfo[i].categoryEn === "SERVICE") {
              localStorage.setItem(
                "serCode",
                response.data.categoryClientInfo[i].id
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Có lỗi xảy ra, vui lòng thử lại sau. ",
            customClass: {
              popup: "my-custom-alert-class",
            },
          });
        });
    }
    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("serCode"),
      })
      .then((response) => {
        setDataContentService(response.data.subContentClient);
        setDataListService(response.data.subCategoryClient);
        setPostHighLight(response.data.outstandingContentClient);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Đã có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });

    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("cateCode"),
      })
      .then((response) => {
        setMenuLeftAbout(response.data.subCategoryClient[0]);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Đã có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idServiceList, idCategory]);

  return (
    <React.Fragment>
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="WrapServiceSites">
            <div className="ServiceSitesBanner">
              <img
                alt="Banner"
                className="ServiceSitesBannerImg"
                src={BannerAir}
              />

              <h1 className="ServiceSitesTitle">DỊCH VỤ</h1>
              <div className="ServiceSitesIcon">
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/air-transport">
                    <img alt="mb" src={img1} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/inland-transport">
                    <img alt="mb" src={img2} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/sea-shipping">
                    <img alt="mb" src={img3} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/warehouse-service">
                    <img alt="mb" src={img4} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/express-delivery">
                    <img alt="mb" src={img5} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
              </div>
              <div className="col-md-12 flex-div">
                <div
                  className="col-md-3"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginBottom: "30px",
                  }}
                >
                  <MyService
                    dataService={dataListService}
                    dataCheckLang={props.dataCheckLang}
                    funcSubCate={props.funcSubCate}
                  />
                  <IntroductionList
                    data={menuLeftAbout}
                    dataCheckLang={props.dataCheckLang}
                  />
                  <HotNews
                    dataPost={postHighLight}
                    dataCheckLang={props.dataCheckLang}
                  />
                </div>
                <div className="col-md-9">
                  <ListService
                    dataService={dataContentService}
                    dataCheckLang={props.dataCheckLang}
                    funcSubCate={props.funcSubCate}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="WrapServiceSites">
            <div className="ServiceSitesBanner">
              <img
                alt="Banner"
                className="ServiceSitesBannerImg"
                src={BannerAir}
              />
              <h1 className="ServiceSitesTitleMb">DỊCH VỤ</h1>
              <div className="ServiceSitesIcon">
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/air-transport">
                    <img alt="mb" src={img1} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/inland-transport">
                    <img alt="mb" src={img2} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/sea-shipping">
                    <img alt="mb" src={img3} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/warehouse-service">
                    <img alt="mb" src={img4} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
                <div className="ServiceSitesIcon-Wrap">
                  <RouterLink to="/service/express-delivery">
                    <img alt="mb" src={img5} className="ServiceSitesIcon-Img" />
                  </RouterLink>
                </div>
              </div>
              <div className="col-md-12 flex-div">
                <div className="col-md-12">
                  <ListService
                    dataService={dataContentService}
                    dataCheckLang={props.dataCheckLang}
                    funcSubCate={props.funcSubCate}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="WrapServiceSites">
          <div className="ServiceSitesBanner">
            <img
              alt="Banner"
              className="ServiceSitesBannerImg"
              src={BannerAir}
            />

            <h1 className="ServiceSitesTitle">SERVICE</h1>
            <div className="ServiceSitesIcon">
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/air-transport">
                  <img alt="mb" src={img1} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/inland-transport">
                  <img alt="mb" src={img2} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/sea-shipping">
                  <img alt="mb" src={img3} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/warehouse-service">
                  <img alt="mb" src={img4} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/express-delivery">
                  <img alt="mb" src={img5} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
            </div>
            <div className="col-md-12 flex-div">
              <div
                className="col-md-3"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  marginBottom: "30px",
                }}
              >
                <MyService
                  dataService={dataListService}
                  dataCheckLang={props.dataCheckLang}
                  funcSubCate={props.funcSubCate}
                />
                <IntroductionList
                  data={menuLeftAbout}
                  dataCheckLang={props.dataCheckLang}
                />
                <HotNews
                  dataPost={postHighLight}
                  dataCheckLang={props.dataCheckLang}
                />
              </div>
              <div className="col-md-9">
                <ListService
                  dataService={dataContentService}
                  dataCheckLang={props.dataCheckLang}
                  funcSubCate={props.funcSubCate}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="WrapServiceSites">
          <div className="ServiceSitesBanner">
            <img
              alt="Banner"
              className="ServiceSitesBannerImg"
              src={BannerAir}
            />
            <h1 className="ServiceSitesTitleMb">SERVICE</h1>
            <div className="ServiceSitesIcon">
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/air-transport">
                  <img alt="mb" src={img1} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/inland-transport">
                  <img alt="mb" src={img2} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/sea-shipping">
                  <img alt="mb" src={img3} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/warehouse-service">
                  <img alt="mb" src={img4} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
              <div className="ServiceSitesIcon-Wrap">
                <RouterLink to="/service/express-delivery">
                  <img alt="mb" src={img5} className="ServiceSitesIcon-Img" />
                </RouterLink>
              </div>
            </div>
            <div className="col-md-12 flex-div">
              <div className="col-md-12">
                <ListService
                  dataCheckLang={props.dataCheckLang}
                  dataService={dataContentService}
                  funcSubCate={props.funcSubCate}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Contact dataCheckLang={props.dataCheckLang} />
      <Footer dataCheckLang={props.dataCheckLang}></Footer>
    </React.Fragment>
  );
}
