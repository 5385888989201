import React, { useEffect } from "react";
import NavBar from "../component/shared/NavBar";
import "../asset/css/Page/HomePage.css";
import { animateScroll as scroll } from "react-scroll";
import { Outlet } from "react-router-dom";

export default function WrapHome(props) {
  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <React.Fragment>
      <div style={{ background: "#F5F5F5" }}>
        <header className="">
          <NavBar
            dataCheckY={props.funcOne}
            dataCheckN={props.funcTwo}
            dataNav={props.dataNav ? props.dataNav : ""}
            dataCheckLang={props.dataCheckLang}
          />
        </header>
        <Outlet></Outlet>
      </div>
    </React.Fragment>
  );
}
