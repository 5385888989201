import React from "react";
import parse from "html-react-parser";
import ReactPlayer from "react-player";

export default function ConvertHTMLtoReactPro(html) {
  const options = {
    replace: (node) => {
      if (node.name === "oembed") {
        return <ReactPlayer url={node.attribs.url} controls />;
      }
    },
  };

  return <div>{parse(html, options)}</div>;
}

