import React, { useEffect, useState } from "react";
import "../../asset/css/content_Home/Service.css";
import img1 from "../../asset/images/Icon/mb.png";
import img2 from "../../asset/images/Icon/tau.png";
import img3 from "../../asset/images/Icon/xe.png";
import img4 from "../../asset/images/Icon/nha.png";
import img5 from "../../asset/images/Icon/khac.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ConvertHTML from "../shared/ConvertHTMLToReact";
import TruncatedContent from "../shared/TruncatedContent";

export default function Service(props) {
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL;
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const [dataServiceHome, setDataServiceHome] = useState();
  const [checkLang, setCheckLang] = useState(false);

  const settingsService = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
  };
  const settingsServiceMb = {
    dots: true,
    infinite: true,
    // autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  useEffect(() => {
    setDataServiceHome(props.dataSlideService);
  }, [props.dataSlideService]);

  return (
    <div
      className="conatiner"
      style={{ paddingTop: "50px", background: "#fff", paddingBottom: "25px" }}
      id="dichvu"
    >
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="container">
            <div className="title-dichvu">DỊCH VỤ CỦA CHÚNG TÔI</div>
            <div className="site-filters text-center ">
              <ul className="nav justify-content-center">
                <li className="col-md-2">
                  {/* <RouterLink to="/service/air-freight-service"> */}
                  <p className="image-tab">
                    <img src={img1} className="service-icon" alt="img" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="col-md-2">
                  {/* <RouterLink to="/service/inland-transportation-service"> */}
                  <p className="image-tab">
                    <img src={img3} className="service-icon" alt="img" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="col-md-2">
                  {/* <RouterLink to="/service/sea-freight-service"> */}
                  <p className="image-tab">
                    <img src={img2} className="service-icon" alt="img" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="col-md-2">
                  {/* <RouterLink to="/service/warehousing-service"> */}
                  <p className="image-tab">
                    <img src={img4} className="service-icon" alt="img" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="col-md-2">
                  {/* <RouterLink to="/service/express-delivery"> */}
                  <p className="image-tab">
                    <img src={img5} className="service-icon" alt="img" />
                  </p>
                  {/* </RouterLink> */}
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="title-dichvu-mb">DỊCH VỤ CỦA CHÚNG TÔI</div>
            <div className="site-filters text-center ">
              <ul className="nav justify-content-center">
                <li className="service-wrapIcon">
                  {/* <RouterLink to="/service/air-freight-service"> */}
                  <p className="image-tab-mb">
                    <img src={img1} alt="img" className="Service-icon-imgMb" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="service-wrapIcon">
                  {/* <RouterLink to="/service/inland-transportation-service"> */}
                  <p className="image-tab-mb">
                    <img src={img3} alt="img" className="Service-icon-imgMb" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="service-wrapIcon">
                  {/* <RouterLink to="/service/sea-freight-service"> */}
                  <p className="image-tab-mb">
                    <img src={img2} alt="img" className="Service-icon-imgMb" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="service-wrapIcon">
                  {/* <RouterLink to="/service/warehousing-service"> */}
                  <p className="image-tab-mb">
                    <img src={img4} alt="img" className="Service-icon-imgMb" />
                  </p>
                  {/* </RouterLink> */}
                </li>
                <li className="service-wrapIcon">
                  {/* <RouterLink to="/service/express-delivery"> */}
                  <p className="image-tab-mb">
                    <img src={img5} alt="img" className="Service-icon-imgMb" />
                  </p>
                  {/* </RouterLink> */}
                </li>
              </ul>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="container">
          <div className="title-dichvu">OUR SERVICES</div>
          <div className="site-filters text-center ">
            <ul className="nav justify-content-center">
              <li className="col-md-2">
                {/* <RouterLink to="/service/air-freight-service"> */}
                <p className="image-tab">
                  <img src={img1} className="service-icon" alt="img" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="col-md-2">
                {/* <RouterLink to="/service/inland-transportation-service"> */}
                <p className="image-tab">
                  <img src={img3} className="service-icon" alt="img" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="col-md-2">
                {/* <RouterLink to="/service/sea-freight-service"> */}
                <p className="image-tab">
                  <img src={img2} className="service-icon" alt="img" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="col-md-2">
                {/* <RouterLink to="/service/warehousing-service"> */}
                <p className="image-tab">
                  <img src={img4} className="service-icon" alt="img" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="col-md-2">
                {/* <RouterLink to="/service/express-delivery"> */}
                <p className="image-tab">
                  <img src={img5} className="service-icon" alt="img" />
                </p>
                {/* </RouterLink> */}
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="title-dichvu-mb">OUR SERVICES</div>
          <div className="site-filters text-center ">
            <ul className="nav justify-content-center">
              <li className="service-wrapIcon">
                {/* <RouterLink to="/service/air-freight-service"> */}
                <p className="image-tab-mb">
                  <img src={img1} alt="img" className="Service-icon-imgMb" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="service-wrapIcon">
                {/* <RouterLink to="/service/inland-transportation-service"> */}
                <p className="image-tab-mb">
                  <img src={img3} alt="img" className="Service-icon-imgMb" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="service-wrapIcon">
                {/* <RouterLink to="/service/sea-freight-service"> */}
                <p className="image-tab-mb">
                  <img src={img2} alt="img" className="Service-icon-imgMb" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="service-wrapIcon">
                {/* <RouterLink to="/service/warehousing-service"> */}
                <p className="image-tab-mb">
                  <img src={img4} alt="img" className="Service-icon-imgMb" />
                </p>
                {/* </RouterLink> */}
              </li>
              <li className="service-wrapIcon">
                {/* <RouterLink to="/service/express-delivery"> */}
                <p className="image-tab-mb">
                  <img src={img5} alt="img" className="Service-icon-imgMb" />
                </p>
                {/* </RouterLink> */}
              </li>
            </ul>
          </div>
        </div>
      )}

      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="container">
            <Slider {...settingsService}>
              {dataServiceHome ? (
                dataServiceHome.map((values, key) => (
                  <div className="service-slide-tab" key={key}>
                    <img
                      alt="VanChuyenHangKhong"
                      src={url + values.imagePresent}
                      className="service-img"
                    />
                    <div className="service-wrapTab">
                      <div className="service-titleTab">
                        {values.subTitleVn}
                      </div>
                      <div className="service-contentTab">
                        {ConvertHTML(values.subContentVn, 503)}
                        <div className="service-wrapBtnTab">
                          <RouterLink to={`/service/${values.paramEn}`}>
                            <button
                              className="service-btnTab"
                              onClick={() => {
                                localStorage.setItem(
                                  "subCodeCate",
                                  values.subCategoryId
                                );
                                props.funcSubCate(values.subCategoryId);
                              }}
                            >
                              CHI TIẾT
                            </button>
                          </RouterLink>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
            <div className="service-taovandon text-center">
              <Link to="/new-shipment" target="blank">
                <button>TẠO VẬN ĐƠN</button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="Service-wrap-slide-mb">
            <Slider {...settingsServiceMb}>
              {dataServiceHome ? (
                dataServiceHome.map((values, key) => (
                  <div className="service-slide-tab-mb" key={key}>
                    <img
                      alt="VanChuyenHangKhong"
                      src={url + values.imagePresent}
                      className="service-img-mb"
                      style={{ width: "100%" }}
                    />
                    <div className="service-wrapTab-mb">
                      <div className="service-titleTab-mb">
                        {values.subTitleVn}
                      </div>
                      <div className="service-contentTab-mb">
                        {/* {ConvertHTML(values.subContentVn)} */}
                        <TruncatedContent content={ConvertHTML(values.subContentVn)} maxHeight={850}/>
                        <div className="service-wrapBtnTab-mb">
                          <RouterLink to={`/service/${values.paramEn}`}>
                            <button
                              className="service-btnTab-mb"
                              onClick={() => {
                                localStorage.setItem(
                                  "subCodeCate",
                                  values.subCategoryId
                                );
                                props.funcSubCate(values.subCategoryId);
                              }}
                            >
                              CHI TIẾT
                            </button>
                          </RouterLink>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>

            <div className="service-taovandon-mb">
              <Link to="/new-shipment" target="blank">
                <button>TẠO VẬN ĐƠN</button>
              </Link>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="container">
          <Slider {...settingsService}>
            {dataServiceHome ? (
              dataServiceHome.map((values, key) => (
                <div className="service-slide-tab" key={key}>
                  <img
                    alt="VanChuyenHangKhong"
                    src={url + values.imagePresent}
                    className="service-img"
                  />
                  <div className="service-wrapTab">
                    <div className="service-titleTab">{values.subTitleEn}</div>
                    <div className="service-contentTab">
                      {ConvertHTML(values.subContentEn)}
                      <div className="service-wrapBtnTab">
                        <RouterLink to={`/service/${values.paramEn}`}>
                          <button
                            className="service-btnTab"
                            onClick={() => {
                              localStorage.setItem(
                                "subCodeCate",
                                values.subCategoryId
                              );
                              props.funcSubCate(values.subCategoryId);
                            }}
                          >
                            DETAIL
                          </button>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </Slider>
          <div className="service-taovandon text-center">
            <Link to="/new-shipment" target="blank">
              <button>CREATE BILL OF LADING</button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="Service-wrap-slide-mb">
          <Slider {...settingsServiceMb}>
            {dataServiceHome ? (
              dataServiceHome.map((values, key) => (
                <div className="service-slide-tab-mb" key={key}>
                  <img
                    alt="VanChuyenHangKhong"
                    src={url + values.imagePresent}
                    className="service-img-mb"
                  />
                  <div className="service-wrapTab-mb">
                    <div className="service-titleTab-mb">
                      {values.subTitleEn}
                    </div>
                    <div className="service-contentTab-mb">
                      {ConvertHTML(values.subContentEn)}
                      <div className="service-wrapBtnTab-mb">
                        <RouterLink to={`/service/${values.paramEn}`}>
                          <button
                            className="service-btnTab-mb"
                            onClick={() => {
                              props.funcSubCate(values.subCategoryId);
                              localStorage.setItem(
                                "subCodeCate",
                                values.subCategoryId
                              );
                            }}
                          >
                            DETAIL
                          </button>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </Slider>

          <div className="service-taovandon-mb">
            <Link to="/new-shipment" target="blank">
              <button>CREATE BILL OF LADING</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
