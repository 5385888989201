import React, { useEffect, useState } from "react";
import "../asset/css/Page/NewShipment.css";
import Footer from "../component/shared/Footer";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useMediaQuery } from "react-responsive";
import { animateScroll as scroll } from "react-scroll";
import Swal from "sweetalert2";
import axios from "../config/axiosConfig";

export default function NewShipment(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const yesterday = new Date(Date.now() - 86400000);
  const [checkLang, setCheckLang] = useState(false);
  const formatDate = (date) => {
    const dateObject = new Date(date);
    const formattedDate = dateObject.toISOString().split("T")[0];
    return formattedDate;
  };

  const onSubmit = (values) => {
    const dataCreate = {
      senderName: values.tableSenderSchema.Name,
      senderAddress: values.tableSenderSchema.Address,
      senderCityName: values.tableSenderSchema.City,
      senderCityCode: values.tableSenderSchema.CityCode,
      senderPostOfficeCode: values.tableSenderSchema.ZipCode,
      senderProvinceName: values.tableSenderSchema.Province,
      senderProvinceCode: values.tableSenderSchema.ProvinceCode,
      senderCountryName: values.tableSenderSchema.Country,
      senderCountryCode: values.tableSenderSchema.CountryCode,
      senderContactName: values.tableSenderSchema.Contact,
      senderPhoneNumber: values.tableSenderSchema.PhoneNumber,
      senderEmail: values.tableSenderSchema.Email,
      receiverName: values.tableReceiverSchema.Name,
      receiverAddress: values.tableReceiverSchema.Address,
      receiverCityName: values.tableReceiverSchema.City,
      receiverCityCode: values.tableReceiverSchema.CityCode,
      receiverPostOfficeCode: values.tableReceiverSchema.ZipCode,
      receiverProvinceName: values.tableReceiverSchema.Province,
      receiverProvinceCode: values.tableReceiverSchema.ProvinceCode,
      receiverCountryName: values.tableReceiverSchema.Country,
      receiverCountryCode: values.tableReceiverSchema.CountryCode,
      receiverContactName: values.tableReceiverSchema.Contact,
      receiverPhoneNumber: values.tableReceiverSchema.PhoneNumber,
      receiverEmail: values.tableReceiverSchema.Email,
      receiverIsHome: values.tableReceiverSchema.Home.toString(),
      partner: values.tableShipmentInfoSchema.Partner,
      description: values.tableShipmentInfoSchema.Description,
      quantity: values.tableShipmentInfoSchema.NumberOfPackages,
      weight: values.tableShipmentInfoSchema.Weight,
      length: values.tableShipmentInfoSchema.Length,
      width: values.tableShipmentInfoSchema.Width,
      height: values.tableShipmentInfoSchema.Height,
      totalWeight: values.tableShipmentInfoSchema.TotalWeight.toString(),
      isDangerous: values.tableShipmentInfoSchema.isDangerous.toString(),
      note: values.tableShipmentInfoSchema.Note,
      countryMadeCode: values.tableShipmentInfoSchema.CountryCode,
      deliveryDate: formatDate(values.tableShipmentInfoSchema.SentDate),
      billCode: values.tableShipmentInfoSchema.BillNumber,
    };
    const bodyCreate = JSON.stringify(dataCreate);
    // console.log(bodyCreate)
    axios
      .post("client/v1/waybill", bodyCreate)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "Tạo vận đơn thành công!",
            text: "Đơn hàng sẽ được gửi tới bạn trong thời gian sớm nhất.",
            customClass: {
              popup: "my-custom-alert-class",
            },
          });
        }
        // console.log(response)
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
          customClass: {
            popup: "my-custom-alert-class",
          },
        });
        console.log(error);
      });
  };

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  const validationSchema = Yup.object().shape({
    tableSenderSchema: Yup.object().shape({
      Name: Yup.string().required("Thông tin này không được để trống!"),
      Address: Yup.string().required("Thông tin này không được để trống!"),
      City: Yup.string(),
      CityCode: Yup.string(),
      ZipCode: Yup.string(),
      Province: Yup.string(),
      ProvinceCode: Yup.string(),
      Country: Yup.string(),
      CountryCode: Yup.string(),
      Contact: Yup.string().required("Thông tin này không được để trống!"),
      PhoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "Số điện thoại chỉ chứa các ký tự số")
        .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
        .max(12, "Số điện thoại không được vượt quá 12 chữ số")
        .required("Thông tin này không được để trống"),
      Email: Yup.string(),
    }),

    tableReceiverSchema: Yup.object().shape({
      Name: Yup.string().required("Thông tin này không được để trống!"),
      Address: Yup.string().required("Thông tin này không được để trống!"),
      City: Yup.string(),
      CityCode: Yup.string(),
      ZipCode: Yup.string(),
      Province: Yup.string(),
      ProvinceCode: Yup.string(),
      Country: Yup.string(),
      CountryCode: Yup.string(),
      Contact: Yup.string().required("Thông tin này không được để trống!"),
      PhoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "Số điện thoại chỉ chứa các ký tự số")
        .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
        .max(12, "Số điện thoại không được vượt quá 12 chữ số")
        .required("Thông tin này không được để trống"),
      Email: Yup.string(),
      Home: Yup.boolean(),
    }),
    tableShipmentInfoSchema: Yup.object().shape({
      Partner: Yup.string(),
      Description: Yup.string().required("Thông tin này không được để trống!"),
      NumberOfPackages: Yup.string(),
      Weight: Yup.string(),
      Length: Yup.string(),
      Width: Yup.string(),
      Height: Yup.string(),
      TotalWeight: Yup.number().integer(),
      isDangerous: Yup.boolean(),
      Note: Yup.string(),
      CountryCode: Yup.string(),
      SentDate: Yup.date()
        .min(yesterday, "Ngày không được là ngày đã trôi qua")
        .required("Thông tin này không được để trống!"),
      BillNumber: Yup.string(),
    }),
  });

  const validationSchemaEn = Yup.object().shape({
    tableSenderSchema: Yup.object().shape({
      Name: Yup.string().required("This information cannot be left blank!"),
      Address: Yup.string().required("This information cannot be left blank!"),
      City: Yup.string(),
      CityCode: Yup.string(),
      ZipCode: Yup.string(),
      Province: Yup.string(),
      ProvinceCode: Yup.string(),
      Country: Yup.string(),
      CountryCode: Yup.string(),
      Contact: Yup.string().required("This information cannot be left blank!"),
      PhoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "Phone numbers contain only numeric characters")
        .min(10, "Phone number must have at least 10 digits")
        .max(12, "Phone number cannot exceed 12 digits")
        .required("This information cannot be left blank"),
      Email: Yup.string(),
    }),

    tableReceiverSchema: Yup.object().shape({
      Name: Yup.string().required("This information cannot be left blank!"),
      Address: Yup.string().required("This information cannot be left blank!"),
      City: Yup.string(),
      CityCode: Yup.string(),
      ZipCode: Yup.string(),
      Province: Yup.string(),
      ProvinceCode: Yup.string(),
      Country: Yup.string(),
      CountryCode: Yup.string(),
      Contact: Yup.string().required("This information cannot be left blank!"),
      PhoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "Số điện thoại chỉ chứa các ký tự số")
        .min(10, "Phone number must have at least 10 digits")
        .max(12, "Phone number cannot exceed 12 digits")
        .required("This information cannot be left blank"),
      Email: Yup.string(),
      Home: Yup.boolean(),
    }),
    tableShipmentInfoSchema: Yup.object().shape({
      Partner: Yup.string(),
      Description: Yup.string().required(
        "This information cannot be left blank!"
      ),
      NumberOfPackages: Yup.string(),
      Weight: Yup.string(),
      Length: Yup.string(),
      Width: Yup.string(),
      Height: Yup.string(),
      TotalWeight: Yup.number().integer(),
      isDangerous: Yup.boolean(),
      Note: Yup.string(),
      CountryCode: Yup.string(),
      SentDate: Yup.date()
        .min(yesterday, "The day that can't be the day has passed")
        .required("This information cannot be left blank!"),
      BillNumber: Yup.string(),
    }),
  });

  const test = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
      customClass: {
        popup: "my-custom-alert-class",
      },
    });
  };

  return (
    <div>
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="WrapNewShipmentPage">
            <div className="NewShipmentTitle" onClick={test}>
              Thêm mới Vận Đơn
            </div>
            <div>
              <Formik
                initialValues={{
                  tableSenderSchema: {
                    Name: "",
                    Address: "",
                    City: "",
                    CityCode: "",
                    ZipCode: "",
                    Province: "",
                    ProvinceCode: "",
                    Country: "",
                    CountryCode: "",
                    Contact: "",
                    PhoneNumber: "",
                    Email: "",
                  },
                  tableReceiverSchema: {
                    Name: "",
                    Address: "",
                    City: "",
                    CityCode: "",
                    ZipCode: "",
                    Province: "",
                    ProvinceCode: "",
                    Country: "",
                    CountryCode: "",
                    Contact: "",
                    PhoneNumber: "",
                    Email: "",
                    Home: false,
                  },
                  tableShipmentInfoSchema: {
                    Partner: "",
                    Description: "",
                    NumberOfPackages: "",
                    Weight: "",
                    Length: "",
                    Width: "",
                    Height: "",
                    TotalWeight: 0,
                    isDangerous: false,
                    Note: "",
                    CountryCode: "",
                    SentDate: "",
                    BillNumber: "",
                  },
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="flex-div NewShipment-background">
                      <div className="col-md-6 form-block">
                        <div className="form-block-m">
                          <div className="text-center NewShipment-TitleTb">
                            Thông tin người gửi hàng
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Công ty/ cá nhân
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableSenderSchema.Name"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.Name"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Địa chỉ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableSenderSchema.Address"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.Address"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.City"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.CityCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã bưu điện
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.ZipCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.Province"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.ProvinceCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Nước
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.Country"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã quốc gia
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.CountryCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Người liên hệ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableSenderSchema.Contact"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.Contact"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Điện thoại
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableSenderSchema.PhoneNumber"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.PhoneNumber"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Email
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableSenderSchema.Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 form-block">
                        <div className="form-block-m">
                          <div className="text-center NewShipment-TitleTb">
                            Thông tin người nhận hàng
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Công ty/ cá nhân
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableReceiverSchema.Name"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.Name"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Địa chỉ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableReceiverSchema.Address"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.Address"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.City"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.CityCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã bưu điện
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.ZipCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.Province"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.ProvinceCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Nước
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.Country"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Mã quốc gia
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.CountryCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Người liên hệ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableReceiverSchema.Contact"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.Contact"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Điện thoại
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS"
                              name="tableReceiverSchema.PhoneNumber"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.PhoneNumber"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label col-md-12">
                              Email
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input"
                              name="tableReceiverSchema.Email"
                            />
                          </div>
                          <div
                            className="form-group wrap-input flex-div"
                            style={{ marginBottom: "0px" }}
                          >
                            <Field
                              type="checkbox"
                              className="NewShipment-checkbox"
                              name="tableReceiverSchema.Home"
                            />
                            <label className="NewShipment-checkbox-label">
                              Là nhà riêng
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 form-block NewShipment-background">
                      <div className="form-block-m">
                        <div className="text-center NewShipment-TitleTb">
                          Thông tin lô hàng
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Đối tác
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.Partner"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Mô tả
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableShipmentInfoSchema.Description"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Description"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Số lượng kiện hàng
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.NumberOfPackages"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.NumberOfPackages"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Trọng lượng (Kg)
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.Weight"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Weight"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="NewShipment-Size flex-div">
                          <div className="form-group wrap-input col-md-4">
                            <label className="NewShipment-label ">
                              Chiều dài (cm)
                            </label>
                            <Field
                              type="text"
                              className="col-md-9 NewShipment-input"
                              name="tableShipmentInfoSchema.Length"
                            />
                            <ErrorMessage
                              name="tableShipmentInfoSchema.Length"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input col-md-4">
                            <label className="NewShipment-label ">
                              Chiều rộng (cm)
                            </label>
                            <Field
                              type="text"
                              className="col-md-9 NewShipment-input"
                              name="tableShipmentInfoSchema.Width"
                            />
                            <ErrorMessage
                              name="tableShipmentInfoSchema.Width"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input col-md-4">
                            <label className="NewShipment-label">
                              Chiều cao (cm)
                            </label>
                            <Field
                              type="text"
                              className="col-md-9 NewShipment-input"
                              name="tableShipmentInfoSchema.Height"
                            />
                            <ErrorMessage
                              name="tableShipmentInfoSchema.Height"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Tổng khối lượng
                          </label>
                          <Field
                            type="number"
                            step="1"
                            min="1"
                            max="100"
                            id="tableShipmentInfoSchema.TotalWeight"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.TotalWeight"
                          />
                        </div>
                        <div className="form-group wrap-input flex-div">
                          <Field
                            type="checkbox"
                            className="NewShipment-checkbox"
                            name="tableShipmentInfoSchema.isDangerous"
                          />
                          <label className="NewShipment-checkbox-label">
                            Hàng nguy hiểm
                          </label>
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Ghi chú
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.Note"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Mã quốc gia sản xuất
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.CountryCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Ngày gửi hàng
                          </label>
                          <Field
                            type="date"
                            className="col-md-12 NewShipment-inputS"
                            name="tableShipmentInfoSchema.SentDate"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.SentDate"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Số Bill
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableShipmentInfoSchema.BillNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 text-center NewShipment-wrapBtnSubmit">
                      <button type="submit" className="btn-newShipment">
                        Lưu thông tin
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <div className="WrapNewShipmentPage">
            <div className="NewShipmentTitleMb">Thêm mới Vận Đơn</div>
            <div>
              <Formik
                initialValues={{
                  tableSenderSchema: {
                    Name: "",
                    Address: "",
                    City: "",
                    CityCode: "",
                    ZipCode: "",
                    Province: "",
                    ProvinceCode: "",
                    Country: "",
                    CountryCode: "",
                    Contact: "",
                    PhoneNumber: "",
                    Email: "",
                  },
                  tableReceiverSchema: {
                    Name: "",
                    Address: "",
                    City: "",
                    CityCode: "",
                    ZipCode: "",
                    Province: "",
                    ProvinceCode: "",
                    Country: "",
                    CountryCode: "",
                    Contact: "",
                    PhoneNumber: "",
                    Email: "",
                    Home: false,
                  },
                  tableShipmentInfoSchema: {
                    Partner: "",
                    Description: "",
                    NumberOfPackages: "",
                    Weight: "",
                    Length: "",
                    Width: "",
                    Height: "",
                    TotalWeight: "",
                    isDangerous: false,
                    Note: "",
                    CountryCode: "",
                    SentDate: "",
                    BillNumber: "",
                  },
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="NewShipment-background">
                      <div className="col-md-12 form-block-Mb">
                        <div className="form-block-mMb">
                          <div className="text-center NewShipment-TitleTb-Mb">
                            Thông tin người gửi hàng
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Công ty/ cá nhân
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableSenderSchema.Name"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.Name"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Địa chỉ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableSenderSchema.Address"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.Address"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.City"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.CityCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã bưu điện
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.ZipCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.Province"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.ProvinceCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Nước
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.Country"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã quốc gia
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.CountryCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Người liên hệ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableSenderSchema.Contact"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.Contact"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Điện thoại
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableSenderSchema.PhoneNumber"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableSenderSchema.PhoneNumber"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Email
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableSenderSchema.Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form-block-Mb">
                        <div className="form-block-mMb">
                          <div className="text-center NewShipment-TitleTb-Mb">
                            Thông tin người nhận hàng
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Công ty/ cá nhân
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableReceiverSchema.Name"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.Name"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Địa chỉ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableReceiverSchema.Address"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.Address"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.City"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã thành phố
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.CityCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã bưu điện
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.ZipCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.Province"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã tỉnh
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.ProvinceCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Nước
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.Country"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Mã quốc gia
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.CountryCode"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Người liên hệ
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableReceiverSchema.Contact"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.Contact"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Điện thoại
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-inputS-mb"
                              name="tableReceiverSchema.PhoneNumber"
                              placeholder="Trường thông tin bắt buộc"
                            />
                            <ErrorMessage
                              name="tableReceiverSchema.PhoneNumber"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input NewShipment-fix">
                            <label className="NewShipment-label-mb col-md-12">
                              Email
                            </label>
                            <Field
                              type="text"
                              className="col-md-12 NewShipment-input-mb"
                              name="tableReceiverSchema.Email"
                            />
                          </div>
                          <div
                            className="form-group wrap-input flex-div"
                            style={{ marginBottom: "10px", marginTop: "25px" }}
                          >
                            <Field
                              type="checkbox"
                              className="NewShipment-checkbox-mb"
                              name="tableReceiverSchema.Home"
                            />
                            <label className="NewShipment-checkbox-label-mb">
                              Là nhà riêng
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 form-block-Mb NewShipment-background">
                      <div className="form-block-mMb">
                        <div className="text-center NewShipment-TitleTb-Mb">
                          Thông tin lô hàng
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Đối tác
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.Partner"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Mô tả
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableShipmentInfoSchema.Description"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Description"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Số lượng kiện hàng
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.NumberOfPackages"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.NumberOfPackages"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Trọng lượng (Kg)
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.Weight"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Weight"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="NewShipment-Size flex-div">
                          <div className="form-group wrap-input col-md-4">
                            <label className="NewShipment-label-mb ">
                              Chiều dài (cm)
                            </label>
                            <Field
                              type="text"
                              className="col-md-9 NewShipment-input-mb"
                              name="tableShipmentInfoSchema.Length"
                            />
                            <ErrorMessage
                              name="tableShipmentInfoSchema.Length"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input col-md-4">
                            <label className="NewShipment-label-mb ">
                              Chiều rộng (cm)
                            </label>
                            <Field
                              type="text"
                              className="col-md-9 NewShipment-input-mb"
                              name="tableShipmentInfoSchema.Width"
                            />
                            <ErrorMessage
                              name="tableShipmentInfoSchema.Width"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                          <div className="form-group wrap-input col-md-4">
                            <label className="NewShipment-label-mb">
                              Chiều cao (cm)
                            </label>
                            <Field
                              type="text"
                              className="col-md-9 NewShipment-input-mb"
                              name="tableShipmentInfoSchema.Height"
                            />
                            <ErrorMessage
                              name="tableShipmentInfoSchema.Height"
                              className="NewShipment-ErrorM"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Tổng khối lượng
                          </label>
                          <Field
                            type="number"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.TotalWeight"
                          />
                        </div>
                        <div
                          className="form-group wrap-input flex-div"
                          style={{ marginTop: "25px" }}
                        >
                          <Field
                            type="checkbox"
                            className="NewShipment-checkbox-mb"
                            name="tableShipmentInfoSchema.isDangerous"
                          />
                          <label className="NewShipment-checkbox-label-mb">
                            Hàng nguy hiểm
                          </label>
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Ghi chú
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.Note"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Mã quốc gia sản xuất
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.CountryCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Ngày gửi hàng
                          </label>
                          <Field
                            type="date"
                            className="col-md-12 NewShipment-DateFix"
                            name="tableShipmentInfoSchema.SentDate"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.SentDate"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Số Bill
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.BillNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 text-center NewShipment-wrapBtnSubmit">
                      <button type="submit" className="btn-newShipment-mb">
                        Lưu thông tin
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="WrapNewShipmentPage">
          <div className="NewShipmentTitle">Add new Bill of Lading</div>
          <div>
            <Formik
              initialValues={{
                tableSenderSchema: {
                  Name: "",
                  Address: "",
                  City: "",
                  CityCode: "",
                  ZipCode: "",
                  Province: "",
                  ProvinceCode: "",
                  Country: "",
                  CountryCode: "",
                  Contact: "",
                  PhoneNumber: "",
                  Email: "",
                },
                tableReceiverSchema: {
                  Name: "",
                  Address: "",
                  City: "",
                  CityCode: "",
                  ZipCode: "",
                  Province: "",
                  ProvinceCode: "",
                  Country: "",
                  CountryCode: "",
                  Contact: "",
                  PhoneNumber: "",
                  Email: "",
                  Home: false,
                },
                tableShipmentInfoSchema: {
                  Partner: "",
                  Description: "",
                  NumberOfPackages: "",
                  Weight: "",
                  Length: "",
                  Width: "",
                  Height: "",
                  TotalWeight: 0,
                  isDangerous: false,
                  Note: "",
                  CountryCode: "",
                  SentDate: "",
                  BillNumber: "",
                },
              }}
              validationSchema={validationSchemaEn}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="flex-div NewShipment-background">
                    <div className="col-md-6 form-block">
                      <div className="form-block-m">
                        <div className="text-center NewShipment-TitleTb">
                          Consignor Information
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Company/individual
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableSenderSchema.Name"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.Name"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Address
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableSenderSchema.Address"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.Address"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            City
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.City"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            City code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.CityCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            ZIP code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.ZipCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Province
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.Province"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Province code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.ProvinceCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Country
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.Country"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Country code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.CountryCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Contact
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableSenderSchema.Contact"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.Contact"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Phone Number
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableSenderSchema.PhoneNumber"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.PhoneNumber"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableSenderSchema.Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 form-block">
                      <div className="form-block-m">
                        <div className="text-center NewShipment-TitleTb">
                          Consignee information
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Company/individual
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableReceiverSchema.Name"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.Name"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Address
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableReceiverSchema.Address"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.Address"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            City
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.City"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            CityCode
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.CityCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            ZIP code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.ZipCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Province
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.Province"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Province code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.ProvinceCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Country
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.Country"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            CountryCode
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.CountryCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Contact
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableReceiverSchema.Contact"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.Contact"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Phone Number
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS"
                            name="tableReceiverSchema.PhoneNumber"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.PhoneNumber"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label col-md-12">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input"
                            name="tableReceiverSchema.Email"
                          />
                        </div>
                        <div
                          className="form-group wrap-input flex-div"
                          style={{ marginBottom: "0px" }}
                        >
                          <Field
                            type="checkbox"
                            className="NewShipment-checkbox"
                            name="tableReceiverSchema.Home"
                          />
                          <label className="NewShipment-checkbox-label">
                            It's a private home
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form-block NewShipment-background">
                    <div className="form-block-m">
                      <div className="text-center NewShipment-TitleTb">
                        Shipment Information
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Partner
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.Partner"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Description
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-inputS"
                          name="tableShipmentInfoSchema.Description"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.Description"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Number of packages
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.NumberOfPackages"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.NumberOfPackages"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Weight (kg)
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.Weight"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.Weight"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="NewShipment-Size flex-div">
                        <div className="form-group wrap-input col-md-4">
                          <label className="NewShipment-label ">
                            Length (cm)
                          </label>
                          <Field
                            type="text"
                            className="col-md-9 NewShipment-input"
                            name="tableShipmentInfoSchema.Length"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Length"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input col-md-4">
                          <label className="NewShipment-label ">
                            Width (cm)
                          </label>
                          <Field
                            type="text"
                            className="col-md-9 NewShipment-input"
                            name="tableShipmentInfoSchema.Width"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Width"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input col-md-4">
                          <label className="NewShipment-label">
                            Height (cm)
                          </label>
                          <Field
                            type="text"
                            className="col-md-9 NewShipment-input"
                            name="tableShipmentInfoSchema.Height"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Height"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Total weight
                        </label>
                        <Field
                          type="number"
                          step="1"
                          min="1"
                          max="100"
                          id="tableShipmentInfoSchema.TotalWeight"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.TotalWeight"
                        />
                      </div>
                      <div className="form-group wrap-input flex-div">
                        <Field
                          type="checkbox"
                          className="NewShipment-checkbox"
                          name="tableShipmentInfoSchema.isDangerous"
                        />
                        <label className="NewShipment-checkbox-label">
                          Hazardous goods
                        </label>
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Note
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.Note"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Country code of manufacture
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.CountryCode"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Delivery date
                        </label>
                        <Field
                          type="date"
                          className="col-md-12 NewShipment-inputS"
                          name="tableShipmentInfoSchema.SentDate"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.SentDate"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label col-md-12">
                          Bill number
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input"
                          name="tableShipmentInfoSchema.BillNumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center NewShipment-wrapBtnSubmit">
                    <button type="submit" className="btn-newShipment">
                      Save information
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="WrapNewShipmentPage">
          <div className="NewShipmentTitleMb">Add new Bill of Lading</div>
          <div>
            <Formik
              initialValues={{
                tableSenderSchema: {
                  Name: "",
                  Address: "",
                  City: "",
                  CityCode: "",
                  ZipCode: "",
                  Province: "",
                  ProvinceCode: "",
                  Country: "",
                  CountryCode: "",
                  Contact: "",
                  PhoneNumber: "",
                  Email: "",
                },
                tableReceiverSchema: {
                  Name: "",
                  Address: "",
                  City: "",
                  CityCode: "",
                  ZipCode: "",
                  Province: "",
                  ProvinceCode: "",
                  Country: "",
                  CountryCode: "",
                  Contact: "",
                  PhoneNumber: "",
                  Email: "",
                  Home: false,
                },
                tableShipmentInfoSchema: {
                  Partner: "",
                  Description: "",
                  NumberOfPackages: "",
                  Weight: "",
                  Length: "",
                  Width: "",
                  Height: "",
                  TotalWeight: "",
                  isDangerous: false,
                  Note: "",
                  CountryCode: "",
                  SentDate: "",
                  BillNumber: "",
                },
              }}
              validationSchema={validationSchemaEn}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="NewShipment-background">
                    <div className="col-md-12 form-block-Mb">
                      <div className="form-block-mMb">
                        <div className="text-center NewShipment-TitleTb-Mb">
                          Consignor Information
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Company/individual
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableSenderSchema.Name"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.Name"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Address
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableSenderSchema.Address"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.Address"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            City
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.City"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            City Code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.CityCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            ZIP Code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.ZipCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Province
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.Province"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Province code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.ProvinceCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Country
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.Country"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            CountryCode
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.CountryCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Contact
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableSenderSchema.Contact"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.Contact"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Phone Number
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableSenderSchema.PhoneNumber"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableSenderSchema.PhoneNumber"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableSenderSchema.Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 form-block-Mb">
                      <div className="form-block-mMb">
                        <div className="text-center NewShipment-TitleTb-Mb">
                          Consignee information
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Company/individual
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableReceiverSchema.Name"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.Name"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Address
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableReceiverSchema.Address"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.Address"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            City
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.City"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            City code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.CityCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            ZIP code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.ZipCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Province
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.Province"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Province code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.ProvinceCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Country
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.Country"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Country code
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.CountryCode"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Contact
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableReceiverSchema.Contact"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.Contact"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Phone
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-inputS-mb"
                            name="tableReceiverSchema.PhoneNumber"
                            placeholder="Trường thông tin bắt buộc"
                          />
                          <ErrorMessage
                            name="tableReceiverSchema.PhoneNumber"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input NewShipment-fix">
                          <label className="NewShipment-label-mb col-md-12">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="col-md-12 NewShipment-input-mb"
                            name="tableReceiverSchema.Email"
                          />
                        </div>
                        <div
                          className="form-group wrap-input flex-div"
                          style={{ marginBottom: "10px", marginTop: "25px" }}
                        >
                          <Field
                            type="checkbox"
                            className="NewShipment-checkbox-mb"
                            name="tableReceiverSchema.Home"
                          />
                          <label className="NewShipment-checkbox-label-mb">
                            It's a private home
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 form-block-Mb NewShipment-background">
                    <div className="form-block-mMb">
                      <div className="text-center NewShipment-TitleTb-Mb">
                        Shipment Information
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Partner
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.Partner"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Delivery
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-inputS-mb"
                          name="tableShipmentInfoSchema.Description"
                          placeholder="Trường thông tin bắt buộc"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.Description"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Number of packages
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.NumberOfPackages"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.NumberOfPackages"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Weight (Kg)
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.Weight"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.Weight"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="NewShipment-Size flex-div">
                        <div className="form-group wrap-input col-md-4">
                          <label className="NewShipment-label-mb ">
                            Length (cm)
                          </label>
                          <Field
                            type="text"
                            className="col-md-9 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.Length"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Length"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input col-md-4">
                          <label className="NewShipment-label-mb ">
                            Width (cm)
                          </label>
                          <Field
                            type="text"
                            className="col-md-9 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.Width"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Width"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                        <div className="form-group wrap-input col-md-4">
                          <label className="NewShipment-label-mb">
                            Height (cm)
                          </label>
                          <Field
                            type="text"
                            className="col-md-9 NewShipment-input-mb"
                            name="tableShipmentInfoSchema.Height"
                          />
                          <ErrorMessage
                            name="tableShipmentInfoSchema.Height"
                            className="NewShipment-ErrorM"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Total weight
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.TotalWeight"
                        />
                      </div>
                      <div
                        className="form-group wrap-input flex-div"
                        style={{ marginTop: "25px" }}
                      >
                        <Field
                          type="checkbox"
                          className="NewShipment-checkbox-mb"
                          name="tableShipmentInfoSchema.isDangerous"
                        />
                        <label className="NewShipment-checkbox-label-mb">
                          Hazardous goods
                        </label>
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Note
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.Note"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Country code of manufacture
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.CountryCode"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Delivery date
                        </label>
                        <Field
                          type="date"
                          className="col-md-12 NewShipment-DateFix"
                          name="tableShipmentInfoSchema.SentDate"
                        />
                        <ErrorMessage
                          name="tableShipmentInfoSchema.SentDate"
                          className="NewShipment-ErrorM"
                          component="div"
                        />
                      </div>
                      <div className="form-group wrap-input NewShipment-fix">
                        <label className="NewShipment-label-mb col-md-12">
                          Bill Number
                        </label>
                        <Field
                          type="text"
                          className="col-md-12 NewShipment-input-mb"
                          name="tableShipmentInfoSchema.BillNumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center NewShipment-wrapBtnSubmit">
                    <button type="submit" className="btn-newShipment-mb">
                      Save information
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      <Footer dataCheckLang={props.dataCheckLang} />
    </div>
  );
}
