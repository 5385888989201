import "./App.css";
import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import HomePage from "./page/HomePage";
import NewShipment from "./page/NewShipment";
import Introduce from "./page/Introduce";
import ServiceSites from "./page/ServiceSites";
import AirTransport from "./component/content_Service/ServiceDetails/AirTransport";
import NewsPage from "./page/NewsPage";
import axiosS from "axios";
import WrapHome from "./page/WrapHome";
import DetailNewsCard from "./component/News/DetailNewsCard";

function App() {
  const [dataHomePage, setDataHomePage] = useState();
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL;
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;

  const [linlDetail, setLinkDetail] = useState();
  const funcSetLink = (link) => {
    setLinkDetail(link);
  };

  const [checkL, setCheckL] = useState(false);
  const funcCheckLY = () => {
    setCheckL(false);
  };
  const funcCheckLN = () => {
    setCheckL(true);
  };
  const [subCodeCate, setSubCodeCate] = useState();
  const funcSubCate = (id) => {
    setSubCodeCate(id);
  };

  useEffect(() => {
    axiosS
      .post(`${url}/client/v1/home`)
      .then((response) => {
        setDataHomePage(response.data);
      })
      .catch((error) => {
        // Xử lý lỗi khi gọi API không thành công
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let routes = [
    {
      path: "/",
      element: (
        <WrapHome
          dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
          funcOne={funcCheckLY}
          funcTwo={funcCheckLN}
          dataCheckLang={checkL}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <HomePage
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataSlideIntro={dataHomePage ? dataHomePage.imageBannerInfo : ""}
              dataSlideService={
                dataHomePage ? dataHomePage.subContentService : ""
              }
              dataSlideNews={dataHomePage ? dataHomePage.subContentNews : ""}
              dataCheckLang={checkL}
              funcLink={funcSetLink}
              funcSubCate={funcSubCate}
            />
          ),
        },
        {
          path: "/new-shipment",
          element: (
            <NewShipment
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataCheckLang={checkL}
            />
          ),
        },
        {
          path: "/introduce",
          element: (
            <Introduce
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataCheckLang={checkL}
              funcSubCate={funcSubCate}
            />
          ),
        },
        {
          path: "/service",
          element: (
            <ServiceSites
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataCheckLang={checkL}
              funcSubCate={funcSubCate}
            />
          ),
        },
        {
          path: "/news",
          element: (
            <NewsPage
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataCheckLang={checkL}
              funcLink={funcSetLink}
            />
          ),
        },
        {
          path: "/news/detailNews",
          element: (
            <DetailNewsCard
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataCheckLang={checkL}
              linkDetail={linlDetail}
            />
          ),
        },
        {
          path: "service/:param",
          element: (
            <AirTransport
              dataNav={dataHomePage ? dataHomePage.categoryClientInfo : ""}
              dataCheckLang={checkL}
              idSubCate={subCodeCate}
              funcSubCate={funcSubCate}
            />
          ),
        },
      ],
    },
  ];
  let element = useRoutes(routes);
  return (
    <div className="home" id="home">
      {element}
    </div>
  );
}

export default App;
