import React, { useEffect, useState } from "react";
import "../../../asset/css/Shared/Ads/HotNews.css";

export default function HotNews(props) {
  // const url = "http://192.168.110.145:8086";
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL

  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  return (
    <React.Fragment>
      {!checkLang ? (
        <div className="HotNewsTitle text-center">TIN TỨC NỔI BẬT</div>
      ) : (
        <div className="HotNewsTitle text-center">FEATURED NEWS</div>
      )}

      <div className="HotNewsListContent">
        {props.dataPost ? (
          props.dataPost.map((values, key) => (
            <div className="HotNewsWrapCt" key={key}>
              <img
                alt="new1"
                src={url + values.imagePresent}
                className="HotNewsImg"
              />
              {!checkLang ? (
                <div className="HotNewsContent">{values.subTitleVn}</div>
              ) : (
                <div className="HotNewsContent">{values.subTitleEn}</div>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
}
