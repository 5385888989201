import React, { useState, useEffect, useRef } from 'react';

const TruncatedContent = ({ content, maxHeight }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const containerHeight = containerRef.current.offsetHeight;
    const textHeight = textRef.current.offsetHeight;

    if (textHeight > containerHeight) {
      setIsTruncated(true);
    }
  }, []);

  return (
    <div ref={containerRef} style={{ maxHeight, overflow: 'hidden' }}>
      <p ref={textRef}>{content}</p>
      {isTruncated && '...'}
    </div>
  );
};

export default TruncatedContent;
