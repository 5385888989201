import React, { useEffect, useState } from "react";
import "../../../asset/css/Shared/Ads/IntroductionList.css";

export default function IntroductionList(props) {
  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  return (
    <React.Fragment>
      <div className="IntroductionListTitle text-center">
        {!checkLang ? "DANH MỤC GIỚI THIỆU" : "INTRODUCTION LIST"}
      </div>
      <div className="IntroductionListContent">
        {props.data ? (
          <ul>
            <li>
              {!checkLang ? props.data.subCategoryVn : props.data.subCategoryEn}
            </li>
          </ul>
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
}
