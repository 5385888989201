import React, { useEffect, useState } from "react";
import "../../../asset/css/Shared/Ads/MyService.css";
import { Link } from "react-router-dom";

export default function MyService(props) {
  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  const switchPage = (id) => {
    props.funcSubCate(id)
    localStorage.setItem("subCodeCate", id)
  };

  return (
    <React.Fragment>
      <div className="AdsMyServiceTitle">
        {!checkLang ? "DỊCH VỤ CỦA CHÚNG TÔI" : "OUR SERVICES"}
      </div>
      <div className="AdsMyServiceList">
        <ul>
          {props.dataService ? (
            props.dataService.map((values, key) => (
              <div key={key} className={key === 0 ? "" : "AdsMyService-item1"}>
                <Link to={`/service/${values.paramEn}`}>
                  <li
                    onClick={() => {
                      switchPage(values.id);
                    }}
                  >
                    {!checkLang ? values.subCategoryVn : values.subCategoryEn}
                  </li>
                </Link>
                <div
                  className={
                    key === props.dataService.length - 1
                      ? "dashedStyle AdsMyService-item2"
                      : "dashedStyle"
                  }
                >
                  ............................................
                </div>
              </div>
            ))
          ) : (
            <> </>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
}
