import React, { useState, useEffect } from "react";
import "../../asset/css/content_Home/TrackingShipment.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import axios from "axios";

export default function TraCuuDonHang(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  const validationSchema = Yup.object().shape({
    search: Yup.string().required("Vui lòng nhập mã đơn"),
  });
  const validationSchemaEn = Yup.object().shape({
    search: Yup.string().required("Please enter the application code"),
  });

  const [data, setData] = useState([]);

  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  return (
    <React.Fragment>
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="container">
            <div className="shipment-block">
              <div className="TrackingShipmet-title-page">
                {/* <img src={img1} alt="" className="tcd" /> */}
                <span>TRA CỨU ĐƠN HÀNG</span>
              </div>
            </div>
            <div className="wrapper-shipment">
              <div className="shipment-body text-center">
                <div>
                  <Formik
                    initialValues={{ search: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      axios
                        .get(
                          "https://achv.asiacargoexpress.com.vn/api/customer/tracking/ace/" +
                            values.search,
                          {
                            headers: {
                              // Authorization: "Bearer " + token,
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then((response) => {
                          setData(response.data);
                          console.log(data);
                        })
                        .catch((error) => {
                          console.log(error);
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
                            customClass: {
                              popup: "my-custom-alert-class",
                            },
                          });
                        });
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="flex-div">
                          <div className="col-md-8">
                            <Field
                              type="text"
                              name="search"
                              placeholder="Mã đơn hàng"
                              className="center-lmh2"
                            />
                            <ErrorMessage
                              name="search"
                              className="error-search"
                              component="div"
                            />
                          </div>
                          <button type="submit" disabled={isSubmitting}>
                            KIỂM TRA NGAY
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="shipment-block">
              <div className="TrackingShipmet-title-page-mb">
                <span>TRA CỨU ĐƠN HÀNG</span>
              </div>
            </div>
            <div className="wrapper-shipment">
              <div className="shipment-body-mb text-center">
                <div>
                  <Formik
                    initialValues={{ search: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div>
                          <Field
                            type="text"
                            name="search"
                            placeholder="Mã đơn hàng"
                            className="center-lmh2"
                          />
                          <button type="submit" disabled={isSubmitting}>
                            KIỂM TRA NGAY
                          </button>
                        </div>
                        <ErrorMessage
                          name="search"
                          className="error-search"
                          component="div"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="container">
          <div className="shipment-block">
            <div className="TrackingShipmet-title-page">
              <span>ORDER LOOKING</span>
            </div>
          </div>
          <div className="wrapper-shipment">
            <div className="shipment-body text-center">
              <div>
                <Formik
                  initialValues={{ search: "" }}
                  validationSchema={validationSchemaEn}
                  onSubmit={(values, { setSubmitting }) => {
                    axios
                      .get(
                        "https://achv.asiacargoexpress.com.vn/api/customer/tracking/ace/" +
                          values.search,
                        {
                          headers: {
                            // Authorization: "Bearer " + token,
                            "Content-Type": "application/json",
                          },
                        }
                      )
                      .then((response) => {
                        setData(response.data);
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error);
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
                          customClass: {
                            popup: "my-custom-alert-class",
                          },
                        });
                      });
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="flex-div">
                        <div className="col-md-8">
                          <Field
                            type="text"
                            name="search"
                            placeholder="Code orders"
                            className="center-lmh2"
                          />
                          <ErrorMessage
                            name="search"
                            className="error-search"
                            component="div"
                          />
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                          CHECK NOW
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="shipment-block">
            <div className="TrackingShipmet-title-page-mb">
              <span>TRA CỨU ĐƠN HÀNG</span>
            </div>
          </div>
          <div className="wrapper-shipment">
            <div className="shipment-body-mb text-center">
              <div>
                <Formik
                  initialValues={{ search: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div>
                        <Field
                          type="text"
                          name="search"
                          placeholder="Mã đơn hàng"
                          className="center-lmh2"
                        />
                        <button type="submit" disabled={isSubmitting}>
                          KIỂM TRA NGAY
                        </button>
                      </div>
                      <ErrorMessage
                        name="search"
                        className="error-search"
                        component="div"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
