import React, { useEffect, useState } from "react";
import "../asset/css/Page/Introduce.css";
import Footer from "../component/shared/Footer";
import Banner from "../asset/images/Banner/banner-dich-vu.jpg";
import WhySunny from "../component/content_Home/WhySunny";
import { useMediaQuery } from "react-responsive";
import MyService from "../component/shared/Advertisement/MyService";
import IntroductionList from "../component/shared/Advertisement/IntroductionList";
import { animateScroll as scroll } from "react-scroll";
import axios from "axios";
import Swal from "sweetalert2";
import ConvertHTML from "../component/shared/ConvertHTMLtoReactPro";

export default function Introduce(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const [dataIntroduce, setDataIntroduce] = useState();
  const [menuLeft, setMenuLeft] = useState();
  const [dataListService, setDataListService] = useState();

  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  let idSubCategory = localStorage.getItem("subCodeCate");
  let idCategory = localStorage.getItem("cateCode");
  let idServiceList = localStorage.getItem("serCode");

  useEffect(() => {}, []);

  useEffect(() => {
    scroll.scrollToTop();
    if (
      localStorage.getItem("subCodeCate") ||
      localStorage.getItem("cateCode") ||
      localStorage.getItem("serCode") === null
    ) {
      axios
        .post(`${url}/client/v1/home`)
        .then((response) => {
          for (let i = 0; i < response.data.categoryClientInfo.length; i++) {
            if (response.data.categoryClientInfo[i].categoryEn === "ABOUT US") {
              localStorage.setItem(
                "cateCode",
                response.data.categoryClientInfo[i].id
              );
              localStorage.setItem(
                "subCodeCate",
                response.data.categoryClientInfo[i].subCategoryClient[0].id
              );
            }
            if (response.data.categoryClientInfo[i].categoryEn === "SERVICE") {
              localStorage.setItem(
                "serCode",
                response.data.categoryClientInfo[i].id
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Có lỗi xảy ra, vui lòng thử lại sau. ",
            customClass: {
              popup: "my-custom-alert-class",
            },
          });
        });
    }

    axios
      .post(`${url}/client/v1/postDetail`, {
        subCategoryId: localStorage.getItem("subCodeCate"),
      })
      .then((response) => {
        setDataIntroduce(response.data);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });

    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("cateCode"),
      })
      .then((response) => {
        setMenuLeft(response.data.subCategoryClient[0]);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });

    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("serCode"),
      })
      .then((response) => {
        setDataListService(response.data.subCategoryClient);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubCategory, idCategory, idServiceList]);

  return (
    <React.Fragment>
      <div className="WrapIntroducePage">
        <div className="IntroduceBanner">
          <img alt="Banner" className="IntroduceBannerImg" src={Banner} />
        </div>
        {isDesktopOrLaptop && dataIntroduce ? (
          <div className="IntroduceWrapContent">
            <div className="col-md-12 flex-div">
              <div className="col-md-3"></div>
              {!checkLang ? (
                <>
                  <div className="col-md-9">
                    <h1 className="IntroduceTitle">{dataIntroduce.titleVn}</h1>
                  </div>
                </>
              ) : (
                <div className="col-md-9">
                  <h1 className="IntroduceTitle">{dataIntroduce.titleEn}</h1>
                </div>
              )}
            </div>

            <div className="IntroduceWrapContentB">
              <div className="col-md-3">
                <MyService
                  dataService={dataListService}
                  dataCheckLang={props.dataCheckLang}
                  funcSubCate={props.funcSubCate}
                />
                <IntroductionList
                  data={menuLeft}
                  dataCheckLang={props.dataCheckLang}
                />
              </div>
              <div className="col-md-9 IntroduceWrapContentR">
                {!checkLang
                  ? ConvertHTML(dataIntroduce.contentVn)
                  : ConvertHTML(dataIntroduce.contentEn)}
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {dataIntroduce ? (
              <div className="IntroduceWrapContentMb">
                <div className="col-md-12">
                  <h1 className="IntroduceTitleMb">{dataIntroduce.titleVn}</h1>
                </div>

                <div className="IntroduceWrapContentB">
                  <div className="IntroduceWrapContentRMb">
                    {ConvertHTML(dataIntroduce.contentVn)}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
        )}
        <WhySunny dataCheckLang={props.dataCheckLang} />
      </div>
      <Footer dataCheckLang={props.dataCheckLang} />
    </React.Fragment>
  );
}
