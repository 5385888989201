import React, { useEffect, useState } from "react";
import Footer from "../component/shared/Footer";
import Contact from "../component/shared/Contact/Contact";
import "../asset/css/Utils/ClassUtils.css";
import "../asset/css/Page/News.css";
import { useMediaQuery } from "react-responsive";
import { animateScroll as scroll } from "react-scroll";
import axios from "axios";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../asset/css/News/NewsCard.css";
import "../asset/css/Utils/ClassUtils.css";

export default function NewsPage(props) {
  const isMobile = useMediaQuery({ minWidth: 1200 });
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const [dataNews, setDataNews] = useState();

  const [checkLang, setCheckLang] = useState(false);

  let idNews = localStorage.getItem("newCode");

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  useEffect(() => {
    scroll.scrollToTop();
    if (localStorage.getItem("newCode") === null) {
      axios.post(`${url}/client/v1/home`).then((response) => {
        for (let i = 0; i < response.data.categoryClientInfo.length; i++) {
          if (response.data.categoryClientInfo[i].categoryEn === "NEWS") {
            localStorage.setItem(
              "newCode",
              response.data.categoryClientInfo[i].id
            );
          }
        }
      });
    }
    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("newCode"),
      })
      .then((response) => {
        setDataNews(response.data.subContentClient);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại sau. ",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idNews]);

  return (
    <>
      {!checkLang ? (
        isMobile ? (
          <div className="container mb-100">
            <div className="text-center fs-2 fw-bold text-uppercase mt-4 mb-4 color-primary">
              Tin tức
            </div>
            <div className="row">
              {dataNews ? (
                dataNews.map((values, key) => (
                  <div key={key} className="col-xl-4 col-6">
                    <div className="news-card mt-4 rounded p-2 position-relative">
                      <div className="rounded overflow-hidden news-card__img">
                        <img
                          className=""
                          src={url + values.imagePresent}
                          alt=""
                        />
                      </div>
                      <div className="text-white fw-bold pt-4 lh-sm news-card__title">
                        {values.subTitleVn}
                      </div>
                      <Link to="/news/detailNews">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="rounded text-uppercase news-card__btn position-absolute bottom-5 news-card__btn"
                          onClick={() => props.funcLink(values.subCategoryId)}
                        >
                          Chi tiết
                        </Button>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="mb-100 news-mb">
            <div className="text-center fs-2 fw-bold text-uppercase mt-4 mb-4 color-primary">
              Tin tức
            </div>
            <div className="row">
              {dataNews ? (
                dataNews.map((values, key) => (
                  <div
                    key={key}
                    className="news-card mt-4 rounded p-2 position-relative"
                  >
                    <div className="rounded overflow-hidden news-card__img">
                      <img
                        className=""
                        src={url + values.imagePresent}
                        alt=""
                      />
                    </div>
                    <div className="text-white fw-bold pt-4 lh-sm news-card__title">
                      {values.subTitleVn}
                    </div>
                    <Link to="/news/detailNews">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="rounded text-uppercase news-card__btn position-absolute bottom-5 news-card__btn"
                        onClick={() => props.funcLink(values.subCategoryId)}
                      >
                        Chi tiết
                      </Button>
                    </Link>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )
      ) : isMobile ? (
        <div className="container mb-100">
          <div className="text-center fs-2 fw-bold text-uppercase mt-4 mb-4 color-primary">
            NEWS
          </div>
          <div className="row">
            {dataNews ? (
              dataNews.map((values, key) => (
                <div key={key} className="col-xl-4 col-6">
                  <div className="news-card mt-4 rounded p-2 position-relative">
                    <div className="rounded overflow-hidden news-card__img">
                      <img
                        className=""
                        src={url + values.imagePresent}
                        alt=""
                      />
                    </div>
                    <div className="text-white fw-bold pt-4 lh-sm news-card__title">
                      {values.subTitleEn}
                    </div>
                    <Link to="/news/detailNews">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="rounded text-uppercase news-card__btn position-absolute bottom-5 news-card__btn"
                        onClick={() => props.funcLink(values.subCategoryId)}
                      >
                        DETAIL
                      </Button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="mb-100 news-mb">
          <div className="text-center fs-2 fw-bold text-uppercase mt-4 mb-4 color-primary">
            NEWS
          </div>
          <div className="row">
            {dataNews ? (
              dataNews.map((values, key) => (
                <div
                  key={key}
                  className="news-card mt-4 rounded p-2 position-relative"
                >
                  <div className="rounded overflow-hidden news-card__img">
                    <img className="" src={url + values.imagePresent} alt="" />
                  </div>
                  <div className="text-white fw-bold pt-4 lh-sm news-card__title">
                    {values.subTitleEn}
                  </div>
                  <Link to="/news/detailNews">
                    <Button
                      variant="secondary"
                      size="sm"
                      className="rounded text-uppercase news-card__btn position-absolute bottom-5 news-card__btn"
                      onClick={() => props.funcLink(values.subCategoryId)}
                    >
                      DETAIL
                    </Button>
                  </Link>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      <Contact dataCheckLang={props.dataCheckLang} />
      <Footer dataCheckLang={props.dataCheckLang} />
    </>
  );
}
