import React, { useEffect, useState } from "react";
import "../../asset/css/Shared/Footer.css";
import logoWhite from "../../asset/images/Logo/sunny-white.png";
import {
  FaPhoneAlt,
  FaHome,
  FaEnvelope,
  FaGlobe,
  FaPlane,
  FaRegStar,
  FaTruck,
  FaReplyAll,
} from "react-icons/fa";
import zalo from "../../asset/images/Logo/zalo.png";
import fb from "../../asset/images/Logo/fb.png";
import tiktok from "../../asset/images/Logo/tiktok.png";
import youtube from "../../asset/images/Logo/youtube.png";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

export default function Footer(props) {
  // contact
  const phoneNumberHotLine = "024 88888879";
  const phoneLinkHotLine = `tel:${phoneNumberHotLine}`;
  const recipientEmail = "sale1@sunnyexpress.com.vn";
  // const emailContent = 'Hello!';
  function handleClickEmail() {
    // const emailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&body=${encodeURIComponent(emailContent)}`;
    const emailLink = `https://mail.google.com/mail`;
    window.open(emailLink);
  }
  function handleClickEmailMb() {
    if (navigator.userAgent.match(/Android/i)) {
      window.location.href = `intent://compose?to=${recipientEmail}#Intent;scheme=mailto;package=com.google.android.gm;end;`;
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      window.location.href = `mailto:${recipientEmail}`;
    } else {
      window.open(`mailto:${recipientEmail}`);
    }
  }
  //---------------------------------------------------------------

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  return (
    <div id="footer" style={{ position: "relative" }}>
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="footer-h footer-top text-white footer-image overlay-black-dark bg-img-fix">
            <div className="FooterTest">
              <div className="container">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="widget widget_about">
                      <img src={logoWhite} alt="" />

                      <p className="m-t10">
                        Sunny Express là một công ty năng động trên thị trường
                        dịch vụ logistic và vận tải, với hàng loạt thiết bị giao
                        hàng và vận tải hàng đầu, cung cấp các giải pháp hậu cần
                        linh hoạt, có độ tin cậy cao cho khách hàng của mình,
                        thuộc nhiều ngành công nghiệp khác nhau.
                      </p>

                      <ul className="dez-social-icon ft-ic">
                        <li style={{ fontWeight: "bold", marginRight: "15px" }}>
                          SOCIAL MEDIA
                        </li>
                        <li className="abc">
                          <a href="https://zalo.me/g/dzvbpe413">
                            <img
                              src={zalo}
                              alt="zalo"
                              className="footer-icon"
                            />
                          </a>
                        </li>
                        <li className="abc">
                          <a href="https://www.facebook.com/ctysunnyexpress">
                            <img src={fb} alt="" className="footer-icon" />
                          </a>
                        </li>
                        <li className="abc">
                          <a href="https://www.facebook.com/ctysunnyexpress">
                            <img src={tiktok} alt="" className="footer-icon" />
                          </a>
                        </li>
                        <li className="abc">
                          <a href="https://www.youtube.com/@sunnyquocte">
                            <img src={youtube} alt="" className="footer-icon" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="widget">
                      <h4 className="m-b10 footer-title">THÔNG TIN LIÊN HỆ</h4>
                      <div className="footer-line-1"></div>
                      <div className="dez-separator-outer">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <ul className="widget_getintuch hin">
                        <Link to={phoneLinkHotLine} className="Footer-link-web">
                          <li>
                            <FaPhoneAlt />
                            &ensp;Hotline: 086 986 2466 - 024 88888879 <br />{" "}
                            (24/7 Support Line)
                          </li>
                        </Link>

                        <li>
                          <FaHome />
                          <a
                            className="Footer-link-web"
                            target="blank"
                            href="https://goo.gl/maps/5SwUfvQ8xZLozAKt7?coh=178573&entry=tt"
                          >
                            &ensp;NV1-KĐT Gelexia 885 Tam Trinh - Hoàng Mai - Hà
                            Nội
                          </a>
                        </li>

                        <li>
                          <div
                            onClick={handleClickEmail}
                            className="Footer-link-web"
                          >
                            <FaEnvelope />
                            &ensp;Email: sale1@sunnyexpress.com.vn
                          </div>
                        </li>
                        <li>
                          <FaGlobe />
                          <a
                            className="Footer-link-web"
                            href="https://sunnyexpress.vn/"
                          >
                            &ensp;Website: https://sunnyexpress.vn/
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-3" style={{ paddingLeft: "25px" }}>
                    <div className="widget">
                      <h4
                        className="m-b10 footer-title"
                        style={{ textTransform: "uppercase" }}
                      >
                        DỊCH VỤ CỦA CHÚNG TÔI
                      </h4>
                      <div className="footer-line-1"></div>
                      <div className="dez-separator-outer">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <ul className="widget_getintuch hin">
                        <li>
                          <Link
                            to="/service/sea-shipping"
                            className="Footer-link-web"
                          >
                            <FaPlane />
                            &ensp;Vận chuyển hàng hóa quốc tế
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/service/air-transport"
                            className="Footer-link-web"
                          >
                            <FaRegStar />
                            &ensp;Giao nhận thủ tục hải quan
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/service/inland-transport"
                            className="Footer-link-web"
                          >
                            <FaTruck />
                            &ensp;Vận chuyển nội địa
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/service/express-delivery"
                            className="Footer-link-web"
                          >
                            <FaReplyAll
                              style={{ transform: "rotateY(-180deg)" }}
                            />
                            &ensp;Chuyển phát nhanh quốc tế
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bot">
                <div className="container">
                  <p>Copyright © AT Tech 2023</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="footer-hMb text-white footer-image overlay-black-dark bg-img-fix">
            <div className="FooterTest Wrap-footer-mb">
              <div className="Footer-introCt-mb">
                <img src={logoWhite} alt="logo" className="logo-footer-mb" />
                <p className="Footer-introText-mb">
                  Sunny Express là một công ty năng động trên thị trường dịch vụ
                  logistic và vận tải, với hàng loạt thiết bị giao hàng và vận
                  tải hàng đầu, cung cấp các giải pháp hậu cần linh hoạt, có độ
                  tin cậy cao cho khách hàng của mình, thuộc nhiều ngành công
                  nghiệp khác nhau.
                </p>
                <div className="Footer-media-mb">
                  <ul className="flex-div">
                    <li
                      style={{
                        fontWeight: "bold",
                        marginRight: "15px",
                        marginBottom: "auto",
                        marginTop: "auto",
                      }}
                    >
                      SOCIAL MEDIA
                    </li>
                    <li className="abc">
                      <a href="https://zalo.me/g/dzvbpe413">
                        <img src={zalo} alt="zalo" className="footer-icon-mb" />
                      </a>
                    </li>
                    <li className="abc">
                      <a href="https://www.facebook.com/ctysunnyexpress">
                        <img src={fb} alt="" className="footer-icon-mb" />
                      </a>
                    </li>
                    <li className="abc">
                      <a href="https://www.facebook.com/ctysunnyexpress">
                        <img src={tiktok} alt="" className="footer-icon-mb" />
                      </a>
                    </li>
                    <li className="abc">
                      <a href="https://www.youtube.com/@sunnyquocte">
                        <img src={youtube} alt="" className="footer-icon-mb" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <div className="widget">
                    <h4 className="footer-title-mb">THÔNG TIN LIÊN HỆ</h4>
                    <div className="footer-line-1"></div>
                    <div className="dez-separator-outer">
                      <div className="dez-separator bg-white"></div>
                    </div>
                    <ul className="widget_getintuch hin">
                      <Link to={phoneLinkHotLine} className="Footer-link-web">
                        <li>
                          <FaPhoneAlt />
                          &ensp;Hotline: 086 986 2466 - 024 88888879 <br />{" "}
                          (24/7 Support Line)
                        </li>
                      </Link>
                      <li>
                        <FaHome />
                        <a
                          className="Footer-link-web"
                          target="blank"
                          href="https://goo.gl/maps/5SwUfvQ8xZLozAKt7?coh=178573&entry=tt"
                        >
                          &ensp;NV1-KĐT Gelexia 885 Tam Trinh - Hoàng Mai - Hà
                          Nội
                        </a>
                      </li>
                      <Link to={handleClickEmailMb} className="Footer-link-web">
                        <li>
                          <FaEnvelope />
                          &ensp;Email: sale1@sunnyexpress.com.vn
                        </li>
                      </Link>
                      <li>
                        <FaGlobe />
                        &ensp;
                        <a
                          style={{ textDecoration: "none", color: "#fff" }}
                          href="https://sunnyexpress.vn/"
                        >
                          Website: https://sunnyexpress.vn/
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div style={{ marginTop: "40px" }}>
                  <div className="widget">
                    <h4
                      className="m-b10 footer-title-mb"
                      style={{ textTransform: "uppercase" }}
                    >
                      DỊCH VỤ CỦA CHÚNG TÔI
                    </h4>
                    <div className="footer-line-1"></div>
                    <div className="dez-separator-outer">
                      <div className="dez-separator bg-white"></div>
                    </div>
                    <ul className="widget_getintuch hin">
                      <li>
                        <Link
                          to="/service/sea-shipping"
                          className="Footer-link-web"
                        >
                          <FaPlane />
                          &ensp;Vận chuyển hàng hóa quốc tế
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service/air-transport"
                          className="Footer-link-web"
                        >
                          <FaRegStar />
                          &ensp;Giao nhận thủ tục hải quan{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service/inland-transport"
                          className="Footer-link-web"
                        >
                          <FaTruck />
                          &ensp;Vận chuyển nội địa{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service/express-delivery"
                          className="Footer-link-web"
                        >
                          <FaReplyAll
                            style={{ transform: "rotateY(-180deg)" }}
                          />
                          &ensp;Chuyển phát nhanh quốc tế{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bot-mb">
                <div className="Copyright-mb">
                  <p>Copyright © AT Tech 2023</p>
                </div>
              </div>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="footer-h footer-top text-white footer-image overlay-black-dark bg-img-fix">
          <div className="FooterTest">
            <div className="container">
              <div className="row">
                <div className="col-sm-5">
                  <div className="widget widget_about">
                    <img src={logoWhite} alt="" />

                    <p className="m-t10">
                      Sunny Express is a dynamic player in the logistics and
                      transportation services market, with a range of leading
                      transport and delivery equipment, providing flexible,
                      highly reliable logistics solutions to its customers.
                      themselves, from many different industries.
                    </p>

                    <ul className="dez-social-icon ft-ic">
                      <li style={{ fontWeight: "bold", marginRight: "15px" }}>
                        SOCIAL MEDIA
                      </li>
                      <li className="abc">
                        <a href="https://zalo.me/g/dzvbpe413">
                          <img src={zalo} alt="zalo" className="footer-icon" />
                        </a>
                      </li>
                      <li className="abc">
                        <a href="https://www.facebook.com/ctysunnyexpress">
                          <img src={fb} alt="" className="footer-icon" />
                        </a>
                      </li>
                      <li className="abc">
                        <a href="https://www.facebook.com/ctysunnyexpress">
                          <img src={tiktok} alt="" className="footer-icon" />
                        </a>
                      </li>
                      <li className="abc">
                        <a href="https://www.youtube.com/@sunnyquocte">
                          <img src={youtube} alt="" className="footer-icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="widget">
                    <h4 className="m-b10 footer-title">CONTACT INFO</h4>
                    <div className="footer-line-1"></div>
                    <div className="dez-separator-outer">
                      <div className="dez-separator bg-white"></div>
                    </div>
                    <ul className="widget_getintuch hin">
                      <Link to={phoneLinkHotLine} className="Footer-link-web">
                        <li>
                          <FaPhoneAlt />
                          &ensp;Hotline: 086 986 2466 - 024 88888879 <br />{" "}
                          (24/7 Support Line)
                        </li>
                      </Link>

                      <li>
                        <FaHome />
                        <a
                          className="Footer-link-web"
                          target="blank"
                          href="https://goo.gl/maps/5SwUfvQ8xZLozAKt7?coh=178573&entry=tt"
                        >
                          &ensp;NV1-KĐT Gelexia 885 Tam Trinh - Hoang Mai - Ha Noi
                        </a>
                      </li>

                      <li>
                        <div
                          onClick={handleClickEmail}
                          className="Footer-link-web"
                        >
                          <FaEnvelope />
                          &ensp;Email: sale1@sunnyexpress.com.vn
                        </div>
                      </li>
                      <li>
                        <FaGlobe />
                        <a
                          className="Footer-link-web"
                          href="https://sunnyexpress.vn/"
                        >
                          &ensp;Website: https://sunnyexpress.vn/
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3" style={{ paddingLeft: "25px" }}>
                  <div className="widget">
                    <h4
                      className="m-b10 footer-title"
                      style={{ textTransform: "uppercase" }}
                    >
                      OUR SERVICES
                    </h4>
                    <div className="footer-line-1"></div>
                    <div className="dez-separator-outer">
                      <div className="dez-separator bg-white"></div>
                    </div>
                    <ul className="widget_getintuch hin">
                      <li>
                        <Link
                          to="/service/sea-shipping"
                          className="Footer-link-web"
                        >
                          <FaPlane />
                          &ensp;International freight
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service/air-transport"
                          className="Footer-link-web"
                        >
                          <FaRegStar />
                          &ensp;Customs clearance delivery
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service/inland-transport"
                          className="Footer-link-web"
                        >
                          <FaTruck />
                          &ensp;Domestic shipping
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service/express-delivery"
                          className="Footer-link-web"
                        >
                          <FaReplyAll
                            style={{ transform: "rotateY(-180deg)" }}
                          />
                          &ensp;International courier
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bot">
              <div className="container">
                <p>Copyright © AT Tech 2023</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="footer-hMb text-white footer-image overlay-black-dark bg-img-fix">
          <div className="FooterTest Wrap-footer-mb">
            <div className="Footer-introCt-mb">
              <img src={logoWhite} alt="logo" className="logo-footer-mb" />
              <p className="Footer-introText-mb">
                Sunny Express là một công ty năng động trên thị trường dịch vụ
                logistic và vận tải, với hàng loạt thiết bị giao hàng và vận tải
                hàng đầu, cung cấp các giải pháp hậu cần linh hoạt, có độ tin
                cậy cao cho khách hàng của mình, thuộc nhiều ngành công nghiệp
                khác nhau.
              </p>
              <div className="Footer-media-mb">
                <ul className="flex-div">
                  <li
                    style={{
                      fontWeight: "bold",
                      marginRight: "15px",
                      marginBottom: "auto",
                      marginTop: "auto",
                    }}
                  >
                    SOCIAL MEDIA
                  </li>
                  <li className="abc">
                    <a href="https://zalo.me/g/dzvbpe413">
                      <img src={zalo} alt="zalo" className="footer-icon-mb" />
                    </a>
                  </li>
                  <li className="abc">
                    <a href="https://www.facebook.com/ctysunnyexpress">
                      <img src={fb} alt="" className="footer-icon-mb" />
                    </a>
                  </li>
                  <li className="abc">
                    <a href="https://www.facebook.com/ctysunnyexpress">
                      <img src={tiktok} alt="" className="footer-icon-mb" />
                    </a>
                  </li>
                  <li className="abc">
                    <a href="https://www.youtube.com/@sunnyquocte">
                      <img src={youtube} alt="" className="footer-icon-mb" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="">
                <div className="widget">
                  <h4 className="footer-title-mb">THÔNG TIN LIÊN HỆ</h4>
                  <div className="footer-line-1"></div>
                  <div className="dez-separator-outer">
                    <div className="dez-separator bg-white"></div>
                  </div>
                  <ul className="widget_getintuch hin">
                    <Link to={phoneLinkHotLine} className="Footer-link-web">
                      <li>
                        <FaPhoneAlt />
                        &ensp;Hotline: 086 986 2466 - 024 88888879 <br /> (24/7
                        Support Line)
                      </li>
                    </Link>
                    <li>
                      <FaHome />
                      <a
                        className="Footer-link-web"
                        target="blank"
                        href="https://goo.gl/maps/5SwUfvQ8xZLozAKt7?coh=178573&entry=tt"
                      >
                        &ensp;NV1-KĐT Gelexia 885 Tam Trinh - Hoàng Mai - Hà Nội
                      </a>
                    </li>
                    <Link to={handleClickEmailMb} className="Footer-link-web">
                      <li>
                        <FaEnvelope />
                        &ensp;Email: sale1@sunnyexpress.com.vn
                      </li>
                    </Link>
                    <li>
                      <FaGlobe />
                      &ensp;
                      <a
                        style={{ textDecoration: "none", color: "#fff" }}
                        href="https://sunnyexpress.vn/"
                      >
                        Website: https://sunnyexpress.vn/
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ marginTop: "40px" }}>
                <div className="widget">
                  <h4
                    className="m-b10 footer-title-mb"
                    style={{ textTransform: "uppercase" }}
                  >
                    DỊCH VỤ CỦA CHÚNG TÔI
                  </h4>
                  <div className="footer-line-1"></div>
                  <div className="dez-separator-outer">
                    <div className="dez-separator bg-white"></div>
                  </div>
                  <ul className="widget_getintuch hin">
                    <li>
                      <Link
                        to="/service/sea-shipping"
                        className="Footer-link-web"
                      >
                        <FaPlane />
                        &ensp;Vận chuyển hàng hóa quốc tế
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service/air-transport"
                        className="Footer-link-web"
                      >
                        <FaRegStar />
                        &ensp;Giao nhận thủ tục hải quan{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service/inland-transport"
                        className="Footer-link-web"
                      >
                        <FaTruck />
                        &ensp;Vận chuyển nội địa{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service/express-delivery"
                        className="Footer-link-web"
                      >
                        <FaReplyAll style={{ transform: "rotateY(-180deg)" }} />
                        &ensp;Chuyển phát nhanh quốc tế{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bot-mb">
              <div className="Copyright-mb">
                <p>Copyright © AT Tech 2023</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="FooterOverlay"></div>
    </div>
  );
}
