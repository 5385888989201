import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "../../asset/css/content_Home/Slide.css";

export default function Slide_Intro(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const [imgBanner, setImgBanner] = useState();
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;

  useEffect(() => {
    setImgBanner(props.dataSlideIntro);
  }, [props.dataSlideIntro]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    draggable: true,
    touchMove: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      {isDesktopOrLaptop ? (
        <div className="container">
          <Slider {...settings}>
            {imgBanner && imgBanner.length ? (
              imgBanner.map((value, key) => (
                <div key={key}>
                  <img
                    src={url + value.path}
                    alt="slide_2"
                    className="SlideIntro-slide"
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </Slider>
        </div>
      ) : (
        <div>
          <Slider {...settings}>
            {imgBanner && imgBanner.length ? (
              imgBanner.map((value, key) => (
                <div key={key}>
                  <img
                    src={url + value.path}
                    alt="slide_2"
                    className="SlideIntro-slide"
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </Slider>
        </div>
      )}
    </React.Fragment>
  );
}
