import React, { useEffect, useState } from "react";
import img1 from "../../../asset/images/Icon/m1.png";
import img2 from "../../../asset/images/Icon/m2.png";
import img3 from "../../../asset/images/Icon/m3.png";
import img4 from "../../../asset/images/Icon/m4.png";
import "../../../asset/css/Shared/Contact/Contact.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import axios from "../../../config/axiosConfig";
import Swal from "sweetalert2";

export default function Contact(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const [checkLang, setCheckLang] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Họ và tên không được để trống"),
    phoneNumber: Yup.string()
      .matches(
        /^(?:\+?84|0)(?:[35789]\d{8}|1[2689]\d{8})$/,
        "Số điện thoại không hợp lệ"
      )
      .required("Số điện thoại không được để trống"),
    email: Yup.string(),
  });
  const validationSchemaEn = Yup.object().shape({
    name: Yup.string().required("First and last name cannot be left blank"),
    phoneNumber: Yup.string()
      .matches(
        /^(?:\+?84|0)(?:[35789]\d{8}|1[2689]\d{8})$/,
        "Invalid phone number"
      )
      .required("Phone number can not be left blank"),
    email: Yup.string(),
  });

  const onSubmit = (values) => {
    const bodyCreate = JSON.stringify(values);
    axios
      .post("client/v1/cost", bodyCreate)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "Cảm ơn bạn đã quan tâm!",
            text: "Chúng tôi sẽ liên hệ sớm nhất với bạn.",
            customClass: {
              popup: 'my-custom-alert-class',
            },
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
          customClass: {
            popup: 'my-custom-alert-class',
          },
        });
        console.log(error);
      });
  };

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  return (
    <div id="whySunny">
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="ContactWrap">
            <h1 className="ContactTitle">HÃY LIÊN HỆ VỚI CHÚNG TÔI</h1>
            <div className="col-md-12 flex-div container ContactWrapContent">
              <div className="col-md-8">
                <div>
                  Để được tư vấn miễn phí và nhiều ưu đãi nếu quý khách là đối
                  tác, khách hàng mới.
                </div>
                <div className="row">
                  <div className="col-6 ContactWrapTab">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img1}
                        alt="icon"
                      />
                      <div className="center-lmh2">Giao hàng nhanh chóng</div>
                    </div>
                  </div>
                  <div className="col-6 ContactWrapTab">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img2}
                        alt="icon"
                      />
                      <div className="center-lmh2">Giá cả hợp lý</div>
                    </div>
                  </div>
                  <div className="col-6 ContactWrapTab">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img3}
                        alt="icon"
                      />
                      <div className="center-lmh2">Giảm thiểu rủi ro</div>
                    </div>
                  </div>
                  <div className="col-6 ContactWrapTab">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img4}
                        alt="icon"
                      />
                      <div className="center-lmh2">Chủ động kiểm soát</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sheet-box-whySunny">
                  <div className="title-custom" id="baogia">
                    ĐĂNG KÝ NHẬN BÁO GIÁ
                    <p>&nbsp;</p>
                  </div>
                  <Formik
                    initialValues={{ name: "", email: "", phoneNumber: "" }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-group">
                          <Field
                            name="name"
                            type="text"
                            placeholder="Tên"
                            className="form-control inputWhySunny-navbar"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group inputWhySunny">
                          <Field
                            name="phoneNumber"
                            type="tel"
                            placeholder="SĐT/Zalo"
                            className="form-control inputWhySunny-navbar"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group inputWhySunny">
                          <Field
                            name="email"
                            type="email"
                            placeholder="Email (có thể không cần)"
                            className="form-control inputWhySunny-navbar"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          NHẬN BÁO GIÁ
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="ContactWrap">
            <h1 className="ContactTitleMb">HÃY LIÊN HỆ VỚI CHÚNG TÔI</h1>
            <div className="ContactWrapContentMb">
              <div className="col-md-12">
                <div className="ContactTextMb">
                  Để được tư vấn miễn phí và nhiều ưu đãi nếu quý khách là đối
                  tác, khách hàng mới.
                </div>
                <div className="row">
                  <div className="col-6 ContactWrapTabMb">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img1}
                        alt="icon"
                      />
                      <div className="center-lmh2">Giao hàng nhanh chóng</div>
                    </div>
                  </div>
                  <div className="col-6 ContactWrapTabMb">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img2}
                        alt="icon"
                      />
                      <div className="center-lmh2">Giá cả hợp lý</div>
                    </div>
                  </div>
                  <div className="col-6 ContactWrapTabMb">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img3}
                        alt="icon"
                      />
                      <div className="center-lmh2">Giảm thiểu rủi ro</div>
                    </div>
                  </div>
                  <div className="col-6 ContactWrapTabMb">
                    <div className="flex-div">
                      <img
                        className="ContactIconImg center-lmh2"
                        src={img4}
                        alt="icon"
                      />
                      <div className="center-lmh2">Chủ động kiểm soát</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="sheet-box-whySunny">
                  <div className="title-custom ContactDKBG" id="baogia">
                    ĐĂNG KÝ NHẬN BÁO GIÁ
                    <p>&nbsp;</p>
                  </div>
                  <Formik
                    initialValues={{ name: "", email: "", phoneNumber: "" }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-group">
                          <Field
                            name="name"
                            type="text"
                            placeholder="Tên"
                            className="form-control inputWhySunny-navbar ContactInput"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group inputWhySunny">
                          <Field
                            name="phoneNumber"
                            type="tel"
                            placeholder="SĐT/Zalo"
                            className="form-control inputWhySunny-navbar ContactInput"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group inputWhySunny">
                          <Field
                            name="email"
                            type="email"
                            placeholder="Email (có thể không cần)"
                            className="form-control inputWhySunny-navbar ContactInput"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <button
                          type="submit"
                          className="ContactBtn btn btn-primary"
                        >
                          NHẬN BÁO GIÁ
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )
      ) : isDesktopOrLaptop ? (
        <div className="ContactWrap">
          <h1 className="ContactTitle">PLEASE CONTACT US</h1>
          <div className="col-md-12 flex-div container ContactWrapContent">
            <div className="col-md-8">
              <div>
                For free consultation and many incentives if you are a new
                partner or customer.
              </div>
              <div className="row">
                <div className="col-6 ContactWrapTab">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img1}
                      alt="icon"
                    />
                    <div className="center-lmh2">Fast shipping</div>
                  </div>
                </div>
                <div className="col-6 ContactWrapTab">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img2}
                      alt="icon"
                    />
                    <div className="center-lmh2">Good price</div>
                  </div>
                </div>
                <div className="col-6 ContactWrapTab">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img3}
                      alt="icon"
                    />
                    <div className="center-lmh2">Risk reduction</div>
                  </div>
                </div>
                <div className="col-6 ContactWrapTab">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img4}
                      alt="icon"
                    />
                    <div className="center-lmh2">Actively control</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sheet-box-whySunny">
                <div className="title-custom" id="baogia">
                  SIGN UP TO RECEIVE PRICE
                  <p>&nbsp;</p>
                </div>
                <Formik
                  initialValues={{ name: "", email: "", phoneNumber: "" }}
                  validationSchema={validationSchemaEn}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <Field
                          name="name"
                          type="text"
                          placeholder="Name"
                          className="form-control inputWhySunny-navbar"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group inputWhySunny">
                        <Field
                          name="phoneNumber"
                          type="tel"
                          placeholder="Phone/Zalo"
                          className="form-control inputWhySunny-navbar"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group inputWhySunny">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email (may not need)"
                          className="form-control inputWhySunny-navbar"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        GET A PRICE
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ContactWrap">
          <h1 className="ContactTitleMb">HÃY LIÊN HỆ VỚI CHÚNG TÔI</h1>
          <div className="ContactWrapContentMb">
            <div className="col-md-12">
              <div className="ContactTextMb">
                Để được tư vấn miễn phí và nhiều ưu đãi nếu quý khách là đối
                tác, khách hàng mới.
              </div>
              <div className="row">
                <div className="col-6 ContactWrapTabMb">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img1}
                      alt="icon"
                    />
                    <div className="center-lmh2">Giao hàng nhanh chóng</div>
                  </div>
                </div>
                <div className="col-6 ContactWrapTabMb">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img2}
                      alt="icon"
                    />
                    <div className="center-lmh2">Giá cả hợp lý</div>
                  </div>
                </div>
                <div className="col-6 ContactWrapTabMb">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img3}
                      alt="icon"
                    />
                    <div className="center-lmh2">Giảm thiểu rủi ro</div>
                  </div>
                </div>
                <div className="col-6 ContactWrapTabMb">
                  <div className="flex-div">
                    <img
                      className="ContactIconImg center-lmh2"
                      src={img4}
                      alt="icon"
                    />
                    <div className="center-lmh2">Chủ động kiểm soát</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="sheet-box-whySunny">
                <div className="title-custom ContactDKBG" id="baogia">
                  ĐĂNG KÝ NHẬN BÁO GIÁ
                  <p>&nbsp;</p>
                </div>
                <Formik
                  initialValues={{ name: "", email: "", phoneNumber: "" }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <Field
                          name="name"
                          type="text"
                          placeholder="Tên"
                          className="form-control inputWhySunny-navbar ContactInput"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group inputWhySunny">
                        <Field
                          name="phoneNumber"
                          type="tel"
                          placeholder="SĐT/Zalo"
                          className="form-control inputWhySunny-navbar ContactInput"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group inputWhySunny">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email (có thể không cần)"
                          className="form-control inputWhySunny-navbar ContactInput"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <button
                        type="submit"
                        className="ContactBtn btn btn-primary"
                      >
                        NHẬN BÁO GIÁ
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
