import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import axios from "axios";
import Swal from "sweetalert2";
import Footer from "../shared/Footer";
import "../../asset/css/News/DetailNews.css";
import ConvertHTMLtoReactPro from "../shared/ConvertHTMLtoReactPro";
import { useMediaQuery } from "react-responsive";

export default function DetailNewsCard(props) {
  // const url = "http://192.168.110.145:8086";
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  
  const [dataDetailNews, setDataDetailNews] = useState();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  useEffect(() => {
    scroll.scrollToTop();
    if (props.linkDetail != null) {
      localStorage.setItem("detailLink", props.linkDetail);
    }
    axios
      .post(`${url}/client/v1/postDetail`, {
        subCategoryId: props.linkDetail
          ? props.linkDetail
          : localStorage.getItem("detailLink"),
      })
      .then((response) => {
        setDataDetailNews(response.data);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại sau. ",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="DetailNewsContainer">
          {!props.dataCheckLang && dataDetailNews ? (
            <div className="DetailNewsWrap container">
              <div className="text-center DetailNewsTitle">
                {dataDetailNews.titleVn}
              </div>
              <div className="DetailNewsContent">{ConvertHTMLtoReactPro(dataDetailNews.contentVn)}</div>
            </div>
          ) : props.dataCheckLang && dataDetailNews ? (
            <div className="DetailNewsWrap container">
              <div className="text-center DetailNewsTitle">
                {dataDetailNews.titleEn}
              </div>
              <div className="DetailNewsContent">{ConvertHTMLtoReactPro(dataDetailNews.contentEn)}</div>
            </div>
          ) : (
            <></>
          )}
          <div className="DetailNewsWrapFooter">
            <Footer dataCheckLang={props.dataCheckLang}></Footer>
          </div>
        </div>
      ) : (
        <div>
          {!props.dataCheckLang && dataDetailNews ? (
            <div className="DetailNewsWrap">
              <div className="text-center DetailNewsTitle">
                {dataDetailNews.titleVn}
              </div>
              <div className="DetailNewsContent">{ConvertHTMLtoReactPro(dataDetailNews.contentVn)}</div>
            </div>
          ) : props.dataCheckLang && dataDetailNews ? (
            <div className="DetailNewsWrap">
              <div className="text-center DetailNewsTitle">
                {dataDetailNews.titleEn}
              </div>
              <div className="DetailNewsContent">{ConvertHTMLtoReactPro(dataDetailNews.contentEn)}</div>
            </div>
          ) : (
            <></>
          )}
          <div>
            <Footer dataCheckLang={props.dataCheckLang}></Footer>
          </div>
        </div>
      )}
    </>
  );
}
