import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import "../../../asset/css/content_Service/ServiceDetails/AirTransport.css";
import BannerDefault from "../../../asset/images/Banner/banner-dich-vu.jpg";
import BannerAir from "../../../asset/images/Service/ServiceDetails/AirTransport/banner.jpg";
import BannerAirWorld from "../../../asset/images/Banner/banner1.png";
import BannerSea from "../../../asset/images/Banner/banner-sea.jpg";
import BannerInLand from "../../../asset/images/Banner/banner-inland.png";
import BannerHome from "../../../asset/images/Banner/banner-home.png";
import MyService from "../../shared/Advertisement/MyService";
import IntroductionList from "../../shared/Advertisement/IntroductionList";
import HotNews from "../../shared/Advertisement/HotNews";
import WhySunny from "../../content_Home/WhySunny";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import axios from "axios";
import ConvertHTMLToReactPro from "../../shared/ConvertHTMLtoReactPro";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

export default function AirTransport(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  // const url = "http://192.168.110.145:8086";
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  const [dataPostDetail, setDataPostDetail] = useState();
  const [menuLeftAbout, setMenuLeftAbout] = useState();
  const [menuLeftNews, setMenuLeftNews] = useState();
  const [dataListService, setDataListService] = useState();
  const [Banner, setBanner] = useState("");
  let checkChange = localStorage.getItem("subCodeCate");
  const [checkLang, setCheckLang] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("subCodeCate") === null) {
      localStorage.setItem("subCodeCate", props.idSubCate);
    }
    // console.log("a", props.idSubCate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  const renderBanner = () => {
    switch (
      localStorage.getItem("subCodeCate")
        ? localStorage.getItem("subCodeCate")
        : props.idSubCate
    ) {
      case "cf1d0ab5-d8af-47bb-a7cb-84078f63b231":
        setBanner(BannerAir);
        break;
      case "b22e57b2-325d-48f4-b0db-85c73d2b5afa":
        setBanner(BannerHome);
        break;
      case "8c8eef4a-8405-4a2b-9dd2-01ddd29ee69e":
        setBanner(BannerSea);
        break;
      case "88d3d9f1-b2b6-4388-93ad-5614b8d21001":
        setBanner(BannerInLand);
        break;
      case "c97d06e4-a983-4287-b5fa-1de316d00cf9":
        setBanner(BannerAirWorld);
        break;
      default:
        setBanner(BannerDefault);
    }
  };

  let idCategory = localStorage.getItem("cateCode");
  let idServiceList = localStorage.getItem("serCode");
  let idPostDetail = localStorage.getItem("subCodeCate")

  useEffect(() => {
    scroll.scrollToTop();
    renderBanner();
    const str = location.pathname.split("/");
    const lastPart = str[str.length - 1];

    if (localStorage.getItem("cateCode") === null) {
      axios
        .post(`${url}/client/v1/home`)
        .then((response) => {
          for (let i = 0; i < response.data.categoryClientInfo.length; i++) {
            if (response.data.categoryClientInfo[i].categoryEn === "SERVICE") {
              localStorage.setItem(
                "cateCode",
                response.data.categoryClientInfo[i].id
              );
              localStorage.setItem(
                "serCode",
                response.data.categoryClientInfo[i].id
              );
              for (let j = 0; j < response.data.categoryClientInfo[i].subCategoryClient.length; j++){
                if (response.data.categoryClientInfo[i].subCategoryClient[j].paramEn === lastPart){
                  localStorage.setItem("subCodeCate",response.data.categoryClientInfo[i].subCategoryClient[j].id)
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Có lỗi xảy ra, vui lòng thử lại sau. ",
            customClass: {
              popup: "my-custom-alert-class",
            },
          });
        });
    }

    axios
      .post(`${url}/client/v1/postDetail`, {
        subCategoryId: props.idSubCate
          ? props.idSubCate
          : localStorage.getItem("subCodeCate"),
      })
      .then((response) => {
        setDataPostDetail(response.data);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Đang cập nhật bài viết, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });
    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("cateCode"),
      })
      .then((response) => {
        setMenuLeftAbout(response.data.subCategoryClient[0]);
        setMenuLeftNews(response.data.outstandingContentClient);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Đã có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });
    axios
      .post(`${url}/client/v1/category`, {
        categoryId: localStorage.getItem("serCode"),
      })
      .then((response) => {
        setDataListService(response.data.subCategoryClient);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Đã có lỗi xảy ra, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCategory, idServiceList]);

  useEffect(() => {
    renderBanner();
    axios
      .post(`${url}/client/v1/postDetail`, {
        subCategoryId: localStorage.getItem("subCodeCate"),
      })
      .then((response) => {
        setDataPostDetail(response.data);
      })
      .catch((error) => {
        console.error(error);
        // Swal.fire({
        //   icon: "warning",
        //   title: "Oops...",
        //   text: "Đang cập nhật bài viết, vui lòng thử lại sau. ",
        //   customClass: {
        //     popup: "my-custom-alert-class",
        //   },
        // });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, checkChange, props.idSubCate, idPostDetail]);

  return (
    <React.Fragment>
      {!checkLang ? (
        isDesktopOrLaptop && dataPostDetail ? (
          <React.Fragment>
            <div className="AirTransportWrapBanner col-md-12 text-center">
              <img alt="banner" src={Banner} className="col-md-9" />
            </div>
            <div className="AirTransportWrapContent col-md-12 flex-div">
              <div className="col-md-9 flex-div center-lmh">
                <div className="col-md-3"></div>
                <div className="col-md-8">
                  <div className="AirTransportTitle">
                    {dataPostDetail.titleVn}
                  </div>
                </div>
              </div>
            </div>

            <div className="AirTransportWrapContent col-md-12 flex-div">
              <div className="col-md-9 flex-div center-lmh">
                <div className="col-md-3">
                  <MyService
                    dataService={dataListService}
                    dataCheckLang={props.dataCheckLang}
                    funcSubCate={props.funcSubCate}
                  />
                  <IntroductionList
                    data={menuLeftAbout}
                    dataCheckLang={props.dataCheckLang}
                  />
                  <HotNews
                    dataCheckLang={props.dataCheckLang}
                    dataPost={menuLeftNews}
                  />
                </div>
                <div className="col-md-8 AirTransportPosts">
                  {ConvertHTMLToReactPro(dataPostDetail.contentVn)}
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <>
            {!isDesktopOrLaptop && dataPostDetail ? (
              <React.Fragment>
                <div className="AirTransportWrapBanner col-md-12 text-center">
                  <img alt="banner" src={Banner} className="" />
                </div>

                <div className="AirTransportWrapContentMb col-md-12 flex-div">
                  <div className="col-md-12 AirTransportPostsMb">
                    <div className="AirTransportTitleMb">
                      {dataPostDetail.titleVn}
                    </div>
                    <div className="AirTransportPosts-1Mb">
                      {ConvertHTMLToReactPro(dataPostDetail.contentVn)}
                    </div>
                    <div className="AirTransportListWrapMb">
                      <Link to="/new-shipment">
                        <button className="AirTransportBtnMb">
                          Tạo vận đơn
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <></>
            )}
          </>
        )
      ) : isDesktopOrLaptop && dataPostDetail ? (
        <React.Fragment>
          <div className="AirTransportWrapBanner col-md-12 text-center">
            <img alt="banner" src={Banner} className="col-md-9" />
          </div>
          <div className="AirTransportWrapContent col-md-12 flex-div">
            <div className="col-md-9 flex-div center-lmh">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <div className="AirTransportTitle">
                  {dataPostDetail.titleEn}
                </div>
              </div>
            </div>
          </div>

          <div className="AirTransportWrapContent col-md-12 flex-div">
            <div className="col-md-9 flex-div center-lmh">
              <div className="col-md-3">
                <MyService
                  dataService={dataListService}
                  dataCheckLang={props.dataCheckLang}
                  funcSubCate={props.funcSubCate}
                />
                <IntroductionList
                  data={menuLeftAbout}
                  dataCheckLang={props.dataCheckLang}
                />
                <HotNews
                  dataCheckLang={props.dataCheckLang}
                  dataPost={menuLeftNews}
                />
              </div>
              <div className="col-md-8 AirTransportPosts">
                {ConvertHTMLToReactPro(dataPostDetail.contentEn)}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <>
          {!isDesktopOrLaptop && dataPostDetail ? (
            <React.Fragment>
              <div className="AirTransportWrapBanner col-md-12 text-center">
                <img alt="banner" src={Banner} className="" />
              </div>

              <div className="AirTransportWrapContentMb col-md-12 flex-div">
                <div className="col-md-12 AirTransportPostsMb">
                  <div className="AirTransportTitleMb">
                    {dataPostDetail.titleEn}
                  </div>
                  <div className="AirTransportPosts-1Mb">
                    {ConvertHTMLToReactPro(dataPostDetail.contentEn)}
                  </div>
                  <div className="AirTransportListWrapMb">
                    <Link to="/new-shipment">
                      <button className="AirTransportBtnMb">
                        Create bill of lading
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <></>
          )}
        </>
      )}

      <WhySunny dataCheckLang={props.dataCheckLang} />
      <Footer dataCheckLang={props.dataCheckLang} />
    </React.Fragment>
  );
}
