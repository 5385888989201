import React, { useEffect, useState } from "react";
import "../asset/css/Page/HomePage.css";
import Footer from "../component/shared/Footer";
import SlideIntro from "../component/content_Home/SlideIntro";
import TrackingShipment from "../component/content_Home/TrackingShipment";
import WhySunny from "../component/content_Home/WhySunny";
import Service from "../component/content_Home/Service";
import TinTuc from "../component/content_Home/News";
import { useMediaQuery } from "react-responsive";
import { animateScroll as scroll } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import FacebookChat from "../component/shared/FacebookChat";
export default function HomePage(props) {
  const [allowCSP, setAllowCSP] = useState(false);
  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  useEffect(() => {
    scroll.scrollToTop();
    localStorage.setItem("LanguageOnPage", false);
    // Kiểm tra nếu trang đang chạy ở domain của bạn, cho phép thiết lập CSP
    if (window.location.hostname === "https://www.facebook.com") {
      setAllowCSP(true);
    }
  }, []);

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  const [dataIdIntroduce, setDataIdIntroduce] = useState();
  const [dataSubIdIntro, setDataSubIdIntro] = useState();
  const [dataListSer, setDataListSer] = useState();

  const funcSetIdIntro = () => {
    localStorage.setItem("cateCode", dataIdIntroduce);
    localStorage.setItem("subCodeCate", dataSubIdIntro);
    localStorage.setItem("serCode", dataListSer);
  };

  useEffect(() => {
    if (props.dataNav !== "") {
      for (let i = 0; i < props.dataNav.length; i++) {
        if (props.dataNav[i].categoryEn === "ABOUT US") {
          setDataIdIntroduce(props.dataNav[i].id);
          setDataSubIdIntro(props.dataNav[i].subCategoryClient[0].id);
        }
        if (props.dataNav[i].categoryEn === "SERVICE") {
          setDataListSer(props.dataNav[i].id)
        }
      }
    }
  }, [props.dataNav]);

  return (
    <React.Fragment>
      {allowCSP && (
        <div>
          <Helmet>
            <meta
              http-equiv="Content-Security-Policy"
              content="frame-ancestors 'self' https://www.facebook.com"
            />
          </Helmet>
          <FacebookChat />
        </div>
      )}
      <div style={{ background: "#F5F5F5" }}>
        <SlideIntro
          dataSlideIntro={props.dataSlideIntro ? props.dataSlideIntro : ""}
        />
        <TrackingShipment dataCheckLang={props.dataCheckLang} />
        <WhySunny dataCheckLang={props.dataCheckLang} />
        {isDesktopOrLaptop ? (
          <div className="container text-center">
            <RouterLink to="/introduce">
              {!checkLang ? (
                <button className="seeMoreWhySunny" onClick={funcSetIdIntro}>
                  Xem thêm
                </button>
              ) : (
                <button className="seeMoreWhySunny" onClick={funcSetIdIntro}>
                  See more
                </button>
              )}
            </RouterLink>
          </div>
        ) : (
          <div className="btnWhySunnyHome">
            <RouterLink to="/introduce">
              {!checkLang ? (
                <button className="seeMoreWhySunny-mb" onClick={funcSetIdIntro}>
                  Xem thêm
                </button>
              ) : (
                <button className="seeMoreWhySunny-mb" onClick={funcSetIdIntro}>
                  See more
                </button>
              )}
            </RouterLink>
          </div>
        )}

        <Service
          dataSlideService={
            props.dataSlideService ? props.dataSlideService : ""
          }
          dataCheckLang={props.dataCheckLang}
          funcSubCate={props.funcSubCate}
        />
        <TinTuc
          dataCheckLang={props.dataCheckLang}
          dataListSlide={props.dataSlideNews}
          functionLink={props.funcLink}
        />
        <Footer dataCheckLang={props.dataCheckLang} />
      </div>
    </React.Fragment>
  );
}
