import React, { useState, useEffect } from "react";
import "../../asset/css/Shared/NavBar.css";
import { FaPhoneAlt, FaEnvelope, FaSearch } from "react-icons/fa";
import englishFlag from "../../asset/images/Logo/Anh.png";
import vietFlag from "../../asset/images/Logo/Viet.png";
import sunnyLogo from "../../asset/images/Logo/sunny01.png";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function NavBar(props) {
  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  // contact
  const phoneNumberHotLine = "024 88888879";
  const phoneLinkHotLine = `tel:${phoneNumberHotLine}`;

  // --------------------------------------------------
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  // Toggle menu--------------
  const [highLightMenu, setHighLightMenu] = useState("");
  const location = useLocation();
  useEffect(() => {
    const startIndex = location.pathname.indexOf("/") + 1;
    const endIndex = location.pathname.indexOf("/", startIndex);
    const firstString = location.pathname.slice(startIndex, endIndex);
    setHighLightMenu(firstString);
  }, [location]);

  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  const [showHeader, setShowHeader] = useState(true);
  const [isSticky, setSticky] = useState(false);

  const [cateHome, setCateHome] = useState([]);
  const [subCategoryService, setSubCategoryService] = useState();

  const [cateHomeEn, setCateHomeEn] = useState([]);

  const handleNextPageSubCate = (id, idCate) => {
    localStorage.setItem("subCodeCate", id);
    localStorage.setItem("listSerCode", idCate);
  };

  const handleNextPageCate = (idCate, idSubCate, id) => {
    localStorage.setItem("cateCode", idCate);
    localStorage.setItem("subCodeCate", idSubCate);
    localStorage.setItem("serCode", id);
  };

  const handleNextPageNews = (id) => {
    localStorage.setItem("newCode", id);
  };

  const handleNextPageService = (id, idCate, name) => {
    localStorage.setItem("serCode", id);
    localStorage.setItem("cateCode", idCate);
    // setHighLightMenu(name);
  };

  const setHighlight = (hl) => {
    // setHighLightMenu(hl);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [idIntroduce, setIdIntroduce] = useState();
  const [idSubIntroduce, setSubIdIntroduce] = useState();
  const [idNews, setIdNews] = useState();
  const [idService, setIdService] = useState();

  useEffect(() => {
    async function sortListNav() {
      let newArrTest = [];
      let subCate = [];
      let newArrTestEn = [];
      for (let i = 0; i < props.dataNav.length; i++) {
        if (props.dataNav[i].categoryEn === "HOME") {
          newArrTest[0] = props.dataNav[i].categoryVn;
          newArrTestEn[0] = props.dataNav[i].categoryEn;
        }
        if (props.dataNav[i].categoryEn === "ABOUT US") {
          newArrTest[1] = props.dataNav[i].categoryVn;
          newArrTestEn[1] = props.dataNav[i].categoryEn;
          setIdIntroduce(props.dataNav[i].id);
          setSubIdIntroduce(props.dataNav[i].subCategoryClient[0].id);
        }
        if (props.dataNav[i].categoryEn === "SERVICE") {
          newArrTest[2] = props.dataNav[i].categoryVn;
          newArrTestEn[2] = props.dataNav[i].categoryEn;
          subCate = props.dataNav[i].subCategoryClient;
          setIdService(props.dataNav[i].id);
          setIdIntroduce(props.dataNav[i].id);
        }
        if (props.dataNav[i].categoryEn === "PRICE") {
          newArrTest[3] = props.dataNav[i].categoryVn;
          newArrTestEn[3] = props.dataNav[i].categoryEn;
        }
        if (props.dataNav[i].categoryEn === "NEWS") {
          newArrTest[4] = props.dataNav[i].categoryVn;
          newArrTestEn[4] = props.dataNav[i].categoryEn;
          setIdNews(props.dataNav[i].id);
        }
        if (props.dataNav[i].categoryEn === "CONTACT") {
          newArrTest[5] = props.dataNav[i].categoryVn;
          newArrTestEn[5] = props.dataNav[i].categoryEn;
        }
        if (props.dataNav[i].categoryEn === "CREATE A SHIPMENT") {
          newArrTest[6] = props.dataNav[i].categoryVn;
          newArrTestEn[6] = props.dataNav[i].categoryEn;
        }
      }
      await setCateHome(newArrTest);
      await setCateHomeEn(newArrTestEn);
      await setSubCategoryService(subCate);
    }
    sortListNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataNav]);

  const handleScroll = () => {
    if (window.pageYOffset === 0) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }

    if (window.pageYOffset > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const test = () => {
    console.log(cateHomeEn);
  };

  return (
    <React.Fragment>
      {isDesktopOrLaptop ? (
        <>
          {showHeader && (
            <div className="wrap-navbar-top">
              <div className="flex-div container">
                <div className="call-mail flex-div">
                  <div className="flex-div clw">
                    <Link
                      to={phoneLinkHotLine}
                      className="Footer-link-web flex-div"
                    >
                      <FaPhoneAlt className="center-lmh2 font-icon" />
                      <div
                        className="mg-icon-navbar center-lmh2"
                        onClick={test}
                      >
                        086 986 2466
                      </div>
                    </Link>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        borderLeft: "1px solid white",
                        height: "100%",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    ></span>
                  </div>

                  <div className="flex-div clw">
                    <a
                      href="https://mail.google.com/mail"
                      target="blank"
                      className="Footer-link-web flex-div"
                    >
                      <FaEnvelope className="center-lmh2 font-icon" />
                      <div className="mg-icon-navbar center-lmh2">
                        sale1@sunnyexpress.com.vn
                      </div>
                    </a>
                  </div>
                </div>

                <div className="search-language clw flex-div">
                  <div className="search-container">
                    <input type="text" name="search" className="inputSearch" />
                    <button className="btnSearch">
                      <FaSearch className="font-icon" />
                    </button>
                  </div>
                  <div className="languageFlags flex-div">
                    <div className="center-lmh2">
                      <img
                        onClick={props.dataCheckY}
                        src={vietFlag}
                        alt="tiếng việt"
                        className="flag-nav-icon fixFlag-nav"
                      />
                    </div>
                    <div className="center-lmh2">
                      <img
                        onClick={props.dataCheckN}
                        src={englishFlag}
                        alt="tiếng anh"
                        className="flag-nav-icon fixFlag-nav"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}

      <div className={`navbar-bottom-w ${isSticky ? "sticky" : ""}`}>
        {!checkLang ? (
          isDesktopOrLaptop && cateHome.length > 0 ? (
            <div className="wrap-navbar-bottom container flex-div">
              <div>
                <RouterLink to="/">
                  <img src={sunnyLogo} alt="logo" className="logoSunny" />
                </RouterLink>
              </div>
              <div className="list-menu-nav">
                <ul className="flex-div center-lmh2">
                  <RouterLink to="/" className="navbar-dropmenu-itemL">
                    <li
                      className={
                        highLightMenu === "" ? "li-nav-pc-active" : "li-nav-pc"
                      }
                    >
                      <div>{cateHome[0]}</div>
                    </li>
                  </RouterLink>

                  <RouterLink to="/introduce" className="navbar-dropmenu-itemL">
                    <li
                      className={
                        highLightMenu === "introduc"
                          ? "li-nav-pc-active"
                          : "li-nav-pc"
                      }
                      onClick={() => setHighlight(cateHome[1])}
                    >
                      <div
                        onClick={() =>
                          handleNextPageCate(
                            idIntroduce,
                            idSubIntroduce,
                            idService
                          )
                        }
                      >
                        {cateHome[1]}
                      </div>
                    </li>
                  </RouterLink>

                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      style={{
                        color:
                          highLightMenu === "servic" ||
                          highLightMenu === "service"
                            ? "#cf1d1d"
                            : "#000",
                        padding: "0",
                        background: "none",
                        border: "none",
                        fontSize: "18px",
                        fontWeight:
                          props.highLight === "serviceSites" ? "600" : "500",
                      }}
                      className="li-nav-pc"
                    >
                      {cateHome[2]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={RouterLink}
                        to="/service"
                        eventKey="/service"
                        style={{ fontWeight: "bold", fontSize: "17px" }}
                        onClick={() =>
                          handleNextPageService(
                            idService,
                            idIntroduce,
                            cateHome[2]
                          )
                        }
                      >
                        Tổng quan
                      </Dropdown.Item>
                      {subCategoryService ? (
                        subCategoryService.map((values, key) => (
                          <Dropdown.Item
                            as={RouterLink}
                            to={`/service/${values.paramEn}`}
                            eventKey="/service/air-transport"
                            style={{ fontSize: "17px" }}
                            onClick={() =>
                              handleNextPageSubCate(
                                values.id,
                                idService,
                                cateHome[2]
                              )
                            }
                            key={key}
                          >
                            {values.subCategoryVn}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <></>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <ScrollLink
                    activeClass="active"
                    to="whySunny"
                    spy={true}
                    smooth={true}
                    duration={1000}
                    offset={-250}
                    className="navbar-dropmenu-itemL"
                  >
                    <li
                      className={
                        highLightMenu === "" ? "li-nav-pc" : "NavBar-toggleItem"
                      }
                    >
                      <div>{cateHome[3]}</div>
                    </li>
                  </ScrollLink>

                  <RouterLink to="/news" className="navbar-dropmenu-itemL">
                    <li
                      className={
                        highLightMenu === "new"
                          ? "li-nav-pc-active"
                          : "li-nav-pc"
                      }
                      onClick={() => setHighlight(cateHome[4])}
                    >
                      <div onClick={() => handleNextPageNews(idNews)}>
                        {cateHome[4]}
                      </div>
                    </li>
                  </RouterLink>

                  <ScrollLink
                    activeClass="active"
                    to="footer"
                    spy={true}
                    smooth={true}
                    duration={1000}
                    className="navbar-dropmenu-itemL"
                  >
                    <li className="li-nav-pc">
                      <div>{cateHome[5]}</div>
                    </li>
                  </ScrollLink>

                  <RouterLink
                    to="/new-shipment"
                    className="navbar-dropmenu-itemL"
                  >
                    <li
                      className={
                        highLightMenu === "new-shipmen"
                          ? "li-nav-pc-active"
                          : "li-nav-pc"
                      }
                      onClick={() => setHighlight(cateHome[6])}
                    >
                      <div>{cateHome[6]}</div>
                    </li>
                  </RouterLink>
                </ul>
              </div>
            </div>
          ) : (
            <div className="wrap-navbar-bottom navBar-wrapMb flex-div">
              <div className="col-md-6">
                <RouterLink to="/">
                  <img src={sunnyLogo} alt="logo" className="logoSunnyMb" />
                </RouterLink>
              </div>
              <div className="wrap-toggle-navbar flex-div">
                <Dropdown
                  show={show}
                  onToggle={handleToggle}
                  className="center-lmh"
                  drop="start"
                >
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#892c0b",
                      borderColor: "#f0ad4e",
                      fontSize: "45px",
                      borderRadius: "10px",
                    }}
                    id="dropdown-basic"
                  >
                    <FaBars className="icon-toggle-navbar" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ minWidth: "330px" }}>
                    <Dropdown.Item
                      className="navbar-dropmenu-item navbar-menuMobile"
                      as={RouterLink}
                      to="/"
                    >
                      {cateHome[0]}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="navbar-dropmenu-item navbar-menuMobile"
                      as={RouterLink}
                      to="/introduce"
                      onClick={() =>
                        handleNextPageCate(idIntroduce, idSubIntroduce)
                      }
                    >
                      {cateHome[1]}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="navbar-dropmenu-item navbar-menuMobile"
                      as={RouterLink}
                      to="/service"
                      onClick={() => handleNextPageService(idService)}
                    >
                      {cateHome[2]}
                    </Dropdown.Item>
                    <Dropdown.Item className="navbar-dropmenu-item navbar-menuMobile">
                      <ScrollLink
                        activeClass="active"
                        to="whySunny"
                        spy={true}
                        smooth={true}
                        duration={1000}
                        className="navbar-dropmenu-itemL"
                      >
                        {cateHome[3]}
                      </ScrollLink>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="navbar-dropmenu-item navbar-menuMobile"
                      as={RouterLink}
                      to="/news"
                      onClick={() => handleNextPageNews(idNews)}
                    >
                      {cateHome[4]}
                    </Dropdown.Item>

                    <Dropdown.Item className="navbar-dropmenu-item navbar-menuMobile">
                      <ScrollLink
                        activeClass="active"
                        to="footer"
                        spy={true}
                        smooth={true}
                        duration={1000}
                        className="navbar-dropmenu-itemL"
                      >
                        {cateHome[5]}
                      </ScrollLink>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="navbar-dropmenu-itemm navbar-menuMobile"
                      as={RouterLink}
                      to="/new-shipment"
                    >
                      {cateHome[6]}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )
        ) : isDesktopOrLaptop && cateHomeEn.length > 0 ? (
          <div className="wrap-navbar-bottom container flex-div">
            <div>
              <RouterLink to="/">
                <img src={sunnyLogo} alt="logo" className="logoSunny" />
              </RouterLink>
            </div>
            <div className="list-menu-nav">
              <ul className="flex-div center-lmh2">
                <RouterLink to="/" className="navbar-dropmenu-itemL">
                  <li
                    onClick={() => setHighlight(cateHomeEn[0])}
                    className={
                      highLightMenu === "" ? "li-nav-pc-active" : "li-nav-pc"
                    }
                  >
                    <div>{cateHomeEn[0]}</div>
                  </li>
                </RouterLink>

                <RouterLink to="/introduce" className="navbar-dropmenu-itemL">
                  <li
                    className={
                      highLightMenu === "introduc"
                        ? "li-nav-pc-active"
                        : "li-nav-pc"
                    }
                    onClick={() => setHighlight(cateHomeEn[1])}
                  >
                    <div
                      onClick={() =>
                        handleNextPageCate(
                          idIntroduce,
                          idSubIntroduce,
                          idService
                        )
                      }
                    >
                      {cateHomeEn[1]}
                    </div>
                  </li>
                </RouterLink>

                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    style={{
                      color:
                        highLightMenu === "servic" ||
                        highLightMenu === "service"
                          ? "#cf1d1d"
                          : "#000",
                      padding: "0",
                      background: "none",
                      border: "none",
                      fontSize: "18px",
                      fontWeight:
                        props.highLight === "serviceSites" ? "600" : "500",
                    }}
                    className="li-nav-pc"
                  >
                    {cateHomeEn[2]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={RouterLink}
                      to="/service"
                      eventKey="/service"
                      style={{ fontWeight: "bold", fontSize: "17px" }}
                      onClick={() =>
                        handleNextPageService(
                          idService,
                          idIntroduce,
                          cateHomeEn[2]
                        )
                      }
                    >
                      Overview
                    </Dropdown.Item>
                    {subCategoryService ? (
                      subCategoryService.map((values, key) => (
                        <Dropdown.Item
                          as={RouterLink}
                          to={`/service/${values.paramEn}`}
                          eventKey="/service/air-transport"
                          style={{ fontSize: "17px" }}
                          onClick={() =>
                            handleNextPageSubCate(
                              values.id,
                              idService,
                              cateHome[2]
                            )
                          }
                          key={key}
                        >
                          {values.subCategoryEn}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <></>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <ScrollLink
                  activeClass="active"
                  to="whySunny"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  offset={-250}
                  className="navbar-dropmenu-itemL"
                >
                  <li
                    className={
                      highLightMenu === "" ? "li-nav-pc" : "NavBar-toggleItem"
                    }
                  >
                    <div>{cateHomeEn[3]}</div>
                  </li>
                </ScrollLink>

                <RouterLink to="/news" className="navbar-dropmenu-itemL">
                  <li
                    className={
                      highLightMenu === "new" ? "li-nav-pc-active" : "li-nav-pc"
                    }
                    onClick={() => setHighlight(cateHomeEn[4])}
                  >
                    <div onClick={() => handleNextPageNews(idNews)}>
                      {cateHomeEn[4]}
                    </div>
                  </li>
                </RouterLink>

                <ScrollLink
                  activeClass="active"
                  to="footer"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  className="navbar-dropmenu-itemL"
                >
                  <li className="li-nav-pc">
                    <div>{cateHomeEn[5]}</div>
                  </li>
                </ScrollLink>

                <RouterLink
                  to="/new-shipment"
                  className="navbar-dropmenu-itemL"
                >
                  <li
                    className={
                      highLightMenu === "new-shipmen"
                        ? "li-nav-pc-active"
                        : "li-nav-pc"
                    }
                    onClick={() => setHighlight(cateHomeEn[6])}
                  >
                    <div>{cateHomeEn[6]}</div>
                  </li>
                </RouterLink>
              </ul>
            </div>
          </div>
        ) : (
          <div className="wrap-navbar-bottom navBar-wrapMb flex-div">
            <div className="col-md-6">
              <RouterLink to="/">
                <img src={sunnyLogo} alt="logo" className="logoSunnyMb" />
              </RouterLink>
            </div>
            <div className="wrap-toggle-navbar flex-div">
              <Dropdown
                show={show}
                onToggle={handleToggle}
                className="center-lmh"
                drop="start"
              >
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#892c0b",
                    borderColor: "#f0ad4e",
                  }}
                  id="dropdown-basic"
                >
                  <FaBars className="icon-toggle-navbar" />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ minWidth: "200px" }}>
                  <Dropdown.Item
                    className="navbar-dropmenu-item"
                    as={RouterLink}
                    to="/"
                  >
                    {cateHomeEn[0]}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="navbar-dropmenu-item"
                    as={RouterLink}
                    to="/introduce"
                    onClick={() =>
                      handleNextPageCate(idIntroduce, idSubIntroduce)
                    }
                  >
                    {cateHomeEn[1]}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="navbar-dropmenu-item"
                    as={RouterLink}
                    to="/service"
                    onClick={() => handleNextPageService(idService)}
                  >
                    {cateHomeEn[2]}
                  </Dropdown.Item>
                  <Dropdown.Item className="navbar-dropmenu-item">
                    <ScrollLink
                      activeClass="active"
                      to="whySunny"
                      spy={true}
                      smooth={true}
                      duration={1000}
                      className="navbar-dropmenu-itemL"
                    >
                      {cateHomeEn[3]}
                    </ScrollLink>
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="navbar-dropmenu-item"
                    as={RouterLink}
                    to="/news"
                    onClick={() => handleNextPageNews(idNews)}
                  >
                    {cateHomeEn[4]}
                  </Dropdown.Item>

                  <Dropdown.Item className="navbar-dropmenu-item">
                    <ScrollLink
                      activeClass="active"
                      to="footer"
                      spy={true}
                      smooth={true}
                      duration={1000}
                      className="navbar-dropmenu-itemL"
                    >
                      {cateHomeEn[5]}
                    </ScrollLink>
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="navbar-dropmenu-itemm"
                    as={RouterLink}
                    to="/new-shipment"
                  >
                    {cateHomeEn[6]}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
