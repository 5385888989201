import React, { useEffect, useState } from "react";
import "../../asset/css/content_Home/News.css";
import { FaCog } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useMediaQuery } from "react-responsive";
import { Link as RouterLink } from "react-router-dom";

export default function TinTuc(props) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  const [checkLang, setCheckLang] = useState(false);

  const [dataSlide, setDataSlide] = useState();
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;

  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    draggable: true,
    // touchMove: true,
    slidesToScroll: 1,
  };

  const settingsMb = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setDataSlide(props.dataListSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataListSlide]);

  useEffect(() => {
    setCheckLang(props.dataCheckLang);
  }, [props.dataCheckLang]);

  const test = () => {
    console.log(dataSlide);
  };
  return (
    <React.Fragment>
      {!checkLang ? (
        isDesktopOrLaptop ? (
          <div className="p-t70" id="tintuc">
            <div className="container">
              <div className="section-head text-center">
                <div className="title-news" onClick={test}>
                  TIN TỨC
                </div>
                <div className="flex-div news-wrapper-title">
                  <hr className="left-line" />
                  <FaCog
                    style={{
                      fontSize: "25px",
                      color: "#892c0b",
                      margin: "0",
                    }}
                  />
                  <hr className="right-line" />
                </div>
              </div>
            </div>

            <div className="news-wrapContent ">
              <Slider {...settings}>
                {dataSlide ? (
                  dataSlide.map((values, key) => (
                    <div className="news-contentTab" key={key}>
                      <img
                        alt=""
                        src={url + values.imagePresent}
                        className="news-img"
                      />
                      <RouterLink to="/news/detailNews">
                        <div
                          className="news-content-text"
                          onClick={() =>
                            props.functionLink(values.subCategoryId)
                          }
                        >
                          {values.subTitleVn}
                        </div>
                      </RouterLink>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Slider>
            </div>
            <div className="text-center">
              <RouterLink to="/news">
                <button className="news-btnThem">Xem thêm</button>
              </RouterLink>
            </div>
          </div>
        ) : (
          <div className="p-t70 p-b30" id="tintuc">
            <div className="container">
              <div className="section-head text-center">
                <div className="title-news-mb">TIN TỨC</div>
                <div className="flex-div news-wrapper-title-mb">
                  <hr className="left-line-mb" />
                  <FaCog
                    style={{
                      fontSize: "50px",
                      color: "#892c0b",
                      margin: "0",
                    }}
                  />
                  <hr className="right-line-mb" />
                </div>
              </div>
            </div>
            <div className="news-wrapContent-mb">
              <Slider {...settingsMb}>
                {props.dataListSlide ? (
                  props.dataListSlide.map((values, key) => (
                    <div className="news-contentTab" key={key}>
                      <img
                        alt=""
                        src={url + values.imagePresent}
                        className="news-img"
                      />
                      <RouterLink to="/news/detailNews">
                        <div
                          className="news-content-text"
                          onClick={() =>
                            props.functionLink(values.subCategoryId)
                          }
                          style={{fontSize:'35px', textAlign:'center'}}
                        >
                          {values.subTitleVn}
                        </div>
                      </RouterLink>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Slider>
            </div>
            <div className="col-md-12 text-center news-wrapBtnAdd">
              <RouterLink to="/news">
                <button className="news-btnThem-mb">Xem thêm</button>
              </RouterLink>
            </div>
          </div>
        )
      ) : (
        isDesktopOrLaptop ? (
          <div className="p-t70" id="tintuc">
            <div className="container">
              <div className="section-head text-center">
                <div className="title-news" onClick={test}>
                  TIN TỨC
                </div>
                <div className="flex-div news-wrapper-title">
                  <hr className="left-line" />
                  <FaCog
                    style={{
                      fontSize: "25px",
                      color: "#892c0b",
                      margin: "0",
                    }}
                  />
                  <hr className="right-line" />
                </div>
              </div>
            </div>

            <div className="news-wrapContent ">
              <Slider {...settings}>
                {dataSlide ? (
                  dataSlide.map((values, key) => (
                    <div className="news-contentTab" key={key}>
                      <img
                        alt=""
                        src={url + values.imagePresent}
                        className="news-img"
                      />
                      <RouterLink to="/news/detailNews">
                        <div
                          className="news-content-text"
                          onClick={() =>
                            props.functionLink(values.subCategoryId)
                          }
                        >
                          {values.subTitleEn}
                        </div>
                      </RouterLink>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Slider>
            </div>
            <div className="text-center">
              <RouterLink to="/news">
                <button className="news-btnThem">Xem thêm</button>
              </RouterLink>
            </div>
          </div>
        ) : (
          <div className="p-t70 p-b30" id="tintuc">
            <div className="container">
              <div className="section-head text-center">
                <div className="title-news-mb">TIN TỨC</div>
                <div className="flex-div news-wrapper-title-mb">
                  <hr className="left-line-mb" />
                  <FaCog
                    style={{
                      fontSize: "50px",
                      color: "#892c0b",
                      margin: "0",
                    }}
                  />
                  <hr className="right-line-mb" />
                </div>
              </div>
            </div>
            <div className="news-wrapContent-mb">
              <Slider {...settingsMb}>
                {props.dataListSlide ? (
                  props.dataListSlide.map((values, key) => (
                    <div className="news-contentTab" key={key}>
                      <img
                        alt=""
                        src={url + values.imagePresent}
                        className="news-img"
                      />
                      <RouterLink to="/news/detailNews">
                        <div
                          className="news-content-text"
                          onClick={() =>
                            props.functionLink(values.subCategoryId)
                          }
                          style={{fontSize:'35px', textAlign:'center'}}
                        >
                          {values.subTitleEn}
                        </div>
                      </RouterLink>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Slider>
            </div>
            <div className="col-md-12 text-center news-wrapBtnAdd">
              <RouterLink to="/news">
                <button className="news-btnThem-mb">Xem thêm</button>
              </RouterLink>
            </div>
          </div>
        )
      )}
    </React.Fragment>
  );
}
