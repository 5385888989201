import React, { useEffect, useState } from "react";
import "../../asset/css/content_Service/ListService.css";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ConvertHTML from "../../component/shared/ConvertHTMLToReact";
import TruncatedContent from "../shared/TruncatedContent";

export default function ListService(prop) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const PC = useMediaQuery({ minWidth: 1593 });
  const MB = useMediaQuery({ minWidth: 1224, maxWidth: 1592 });
  // const url = "http://192.168.110.145:8086";
  const url = process.env.REACT_APP_SUNNY_CLIENT_URL;
  // const url = window.REACT_APP_SUNNY_CLIENT_URL;

  const [checkLang, setCheckLang] = useState(false);

  useEffect(() => {
    setCheckLang(prop.dataCheckLang);
  }, [prop.dataCheckLang]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //  ++++++++++++++ phan trang ++++++++++++++++++++++++

  const itemsPerPage = 6; // Số phần tử trên mỗi trang
  const itemsPerRow = 3; // Số phần tử trên mỗi dòng
  const rowsPerPage = Math.ceil(itemsPerPage / itemsPerRow); // Số dòng trên mỗi trang
  const pageCount = Math.ceil(prop.dataService.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = prop.dataService.slice(startIndex, endIndex);
  // ---------------------------------------------------------

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < rowsPerPage; i++) {
      const rowItems = currentPageItems.slice(
        i * itemsPerRow,
        (i + 1) * itemsPerRow
      );
      const row = (
        <div key={i} className="row" style={{ marginBottom: "30px" }}>
          {rowItems.map((item) => (
            <React.Fragment key={item.id}>
              {!checkLang && PC ? (
                <div className="col-md-4 ListService-WrapTab">
                  <img
                    alt="service"
                    src={url + item.imagePresent}
                    className="ListService-img"
                  />
                  <div className="ListServiceWrapContent">
                    <div className="ListServiceTitle">{item.subTitleVn}</div>
                    <div className="ListServiceContent">
                      {ConvertHTML(item.subContentVn, 500)}
                    </div>
                    <Link to={`/service/${item.paramEn}`}>
                      <button
                        onClick={() => prop.funcSubCate(item.subCategoryId)}
                      >
                        CHI TIẾT
                      </button>
                    </Link>
                  </div>
                </div>
              ) : checkLang && PC ? (
                <div className="col-md-4 ListService-WrapTab">
                  <img
                    alt="service"
                    src={url + item.imagePresent}
                    className="ListService-img"
                  />
                  <div className="ListServiceWrapContent">
                    <div className="ListServiceTitle">{item.subTitleEn}</div>
                    <div className="ListServiceContent">
                      {ConvertHTML(item.subContentEn, 500)}
                    </div>
                    <Link to={`/service/${item.paramEn}`}>
                      <button
                        onClick={() => prop.funcSubCate(item.subCategoryId)}
                      >
                        DETAIL
                      </button>
                    </Link>
                  </div>
                </div>
              ) : !checkLang && MB ? (
                <div className="col-md-4 ListService-WrapTab">
                  <img
                    alt="service"
                    src={url + item.imagePresent}
                    className="ListService-img"
                  />
                  <div className="ListServiceWrapContent">
                    <div className="ListServiceTitle">{item.subTitleVn}</div>
                    <div className="ListServiceContent">
                      {ConvertHTML(item.subContentVn, 220)}
                    </div>
                    <Link to={`/service/${item.paramVn}`}>
                      <button
                        onClick={() => prop.funcSubCate(item.subCategoryId)}
                      >
                        CHI TIẾT
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="col-md-4 ListService-WrapTab">
                  <img
                    alt="service"
                    src={url + item.imagePresent}
                    className="ListService-img"
                  />
                  <div className="ListServiceWrapContent">
                    <div className="ListServiceTitle">{item.subTitleEn}</div>
                    <div className="ListServiceContent">
                      {ConvertHTML(item.subContentEn, 220)}
                    </div>
                    <Link to={`/service/${item.paramEn}`}>
                      <button
                        onClick={() => prop.funcSubCate(item.subCategoryId)}
                      >
                        CHI TIẾT 
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      );
      rows.push(row);
    }
    return rows;
  };

  // fix content service -------

  // ------------------------------------------

  useEffect(() => {
    const handlePageChange = () => {
      scrollToTop();
    };

    const paginationContainer = document.querySelector(".pagination");
    if (paginationContainer) {
      paginationContainer.addEventListener("click", handlePageChange);
    }

    return () => {
      if (paginationContainer) {
        paginationContainer.removeEventListener("click", handlePageChange);
      }
    };
  }, []);

  return (
    <React.Fragment>
      {isDesktopOrLaptop ? (
        <>{currentPageItems ? renderRows() : <></>}</>
      ) : (
        <>
          {prop.dataService ? (
            prop.dataService.map((values, key) => (
              <div className=" ListService-WrapTabMb" key={key}>
                <img
                  alt="service"
                  src={url + values.imagePresent}
                  className="ListService-img"
                />
                <div className="ListServiceWrapContentMb">
                  <div className="ListServiceTitleMb">
                    <div className="ListServiceTitle">{values.subTitleVn}</div>
                  </div>
                  <div className="ListServiceContentMb">
                    <TruncatedContent content={ConvertHTML(values.subContentVn, 400)} maxHeight={480}/>
                  </div>
                  <Link to={`/service/${values.paramEn}`}>
                    <button
                      onClick={() => prop.funcSubCate(values.subCategoryId)}
                    >
                      CHI TIẾT
                    </button>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </>
      )}

      {isDesktopOrLaptop ? (
        !checkLang ? (
          <ReactPaginate
            previousLabel={currentPage === 0 ? null : "Trang trước"}
            nextLabel={currentPage === pageCount - 1 ? null : "Trang sau"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            previousClassName={currentPage === 0 ? "disabled" : "previous"}
            nextClassName={currentPage === pageCount - 1 ? "disabled" : "next"}
            previousLinkClassName={
              currentPage === 0 ? "disabled" : "previous-link"
            }
            nextLinkClassName={
              currentPage === pageCount - 1 ? "disabled" : "next-link"
            }
            disabledClassName={"disabled"}
          />
        ) : (
          <ReactPaginate
            previousLabel={currentPage === 0 ? null : "Previous page"}
            nextLabel={currentPage === pageCount - 1 ? null : "Next page"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            previousClassName={currentPage === 0 ? "disabled" : "previous"}
            nextClassName={currentPage === pageCount - 1 ? "disabled" : "next"}
            previousLinkClassName={
              currentPage === 0 ? "disabled" : "previous-link"
            }
            nextLinkClassName={
              currentPage === pageCount - 1 ? "disabled" : "next-link"
            }
            disabledClassName={"disabled"}
          />
        )
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
